import type {
    AiFieldStatus,
    BlockAbstract,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    KanbanColumnsSort,
    Pagination,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    TableColumns,
    TableColumnWidth,
    ViewBlockAbstract
} from '@lighthouse/core'
import { type ApplicationPreviewEnum, type FlowLayoutNode, fieldFileFilter, findNormalOrSyncBlock } from '@lighthouse/shared'
import type { BreakPointSize } from '@lighthouse/tools'
import produce from 'immer'
import { useAtomCallback } from 'jotai/utils'
import type { atomWithImmer } from 'jotai-immer'
import React, { Suspense, useCallback, useMemo } from 'react'

import { syncComponentsAtom } from '@/atoms/application/state'
import { pageBlocksAtom } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'
import { uploadManagerInAppParams } from '@/utils/auth'

interface ViewBlockRenderProps {
    // common
    dataSource: DataSourceAbstract
    blockData: ViewBlockAbstract
    // 视图
    dataSourceList: DataSourceAbstract[]
    records?: RecordLikeProtocol[]
    breakPoint: BreakPointSize
    previewType: ApplicationPreviewEnum
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords: string[]
    blockWidth: number
    tablePropsCache: TableColumns
    tableColumnCache?: TableColumnWidth
    cachedKanbanSort?: KanbanColumnsSort
    pagination: Pagination
    // 视图
    onSelectedRecords: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordAdd: (initialRecordValue?: Record<string, string | number>) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol) => string
    // 高级视图
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    // 日历视图
    onUpdateRecord: (recordId: string, content: RecordLikeProtocol['content']) => Promise<RecordLikeProtocol>
    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>

    onSelectModeChange?: (mode?: SelectedMode) => void

    onTableColumnWidthChange: (val: TableColumnWidth) => void
    onChangeCachedKanbanSort: (val: KanbanColumnsSort | undefined) => void
    pageTarget?: string
    // 自定义视图
    node: FlowLayoutNode
}

const AdvancedTableBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.AdvancedTableBlock })))
const CalendarBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.CalendarBlock })))
const GalleryBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.GalleryBlock })))
const KanbanBoardBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.KanbanBoardBlock })))
const ListBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.ListBlock })))
const TableBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.TableBlock })))
const CustomViewBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.CustomViewBlock })))

export const ViewBlockRender: React.FC<ViewBlockRenderProps> = props => {
    const { blockData, dataSource } = props
    const { config } = blockData
    const { viewType } = config
    const appId = useCurrentAppId()
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const uploadData = useMemo(
        () => ({
            uploadOptions: {
                // TODO: @kidrue id后续处理掉 不需要此参数
                info: { id: '', groupId: dataSource?.id ?? '', label: dataSource?.name ?? '' },
                options: {
                    ...uploadManagerInAppParams(appId),
                    fileFilter: fieldFileFilter
                }
            },
            videoUploadOptions: {
                info: { id: '', groupId: dataSource?.id ?? '', label: dataSource?.name ?? '' },
                options: {
                    ...uploadManagerInAppParams(appId)
                }
            },
            richTextUploadOptions: uploadManagerInAppParams(appId)
        }),
        [appId, dataSource?.id, dataSource?.name]
    )

    const getIsVisible = useVisibilityFilter({ prev, curr })

    const rec = useCallback(
        (
            nodes: FlowLayoutNode[],
            currentPageBlocks: BlockAbstract[],
            syncComponents: BlockAbstract[],
            record: RecordLikeProtocol
        ): FlowLayoutNode[] => {
            return nodes.reduce<FlowLayoutNode[]>((total, current) => {
                const block = findNormalOrSyncBlock({ id: current.id, scope: current.scope }, currentPageBlocks, syncComponents)
                // block?.id === 'container-W066472f27p2'
                if (!block) {
                    return total
                }
                if (!block.config.breakPoint?.visibility) {
                    if (current.type === 'container' && current.children) {
                        return [...total, { ...current, children: rec(current.children, currentPageBlocks, syncComponents, record) }]
                    }
                    return [...total, current]
                }
                const visible = getIsVisible({
                    visibility: block.config.breakPoint?.visibility,
                    viewRecord: {
                        record,
                        datasource: dataSource
                    }
                })

                if (!visible) {
                    return total
                }

                if (current.type === 'container' && current.children) {
                    return [...total, { ...current, children: rec(current.children, currentPageBlocks, syncComponents, record) }]
                }

                return [...total, current]
            }, [])
        },
        [dataSource, getIsVisible]
    )

    const handleFilterNode = useAtomCallback((get, _, node: FlowLayoutNode, record: RecordLikeProtocol) => {
        if (node.type === 'container' && node.children) {
            const currentPageBlocks = get(pageBlocksAtom(pageId))
            const syncComponents = get(syncComponentsAtom)
            return produce(node, draft => {
                if (draft.children) {
                    draft.children = rec(draft.children, currentPageBlocks, syncComponents, record)
                }
            })
        }
        return node
    })

    const viewBlock = useMemo(() => {
        switch (viewType) {
            case 'table': {
                return <TableBlock {...props} />
            }
            case 'advancedTable': {
                return <AdvancedTableBlock {...props} {...uploadData} />
            }
            case 'list': {
                return <ListBlock {...props} />
            }
            case 'gallery': {
                return <GalleryBlock {...props} />
            }
            case 'kanban': {
                return <KanbanBoardBlock {...props} />
            }
            case 'calendar': {
                return <CalendarBlock {...props} />
            }
            case 'custom': {
                return <CustomViewBlock {...props} onFilterNode={handleFilterNode} readonly />
            }
            default: {
                return null
            }
        }
    }, [handleFilterNode, props, uploadData, viewType])

    return <Suspense fallback={<div />}>{viewBlock}</Suspense>
}
