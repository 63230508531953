import {
    ByecodeUIConfigProvider,
    ByecodeUIThemeProvider,
    Loading,
    ModalsProvider,
    ScannerProvider,
    Toast,
    ToastContainer
} from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { ApplicationType, AppUserStatus } from '@lighthouse/core'
import {
    ApplicationProvider,
    AppThemeContextProvider,
    AuthenticationProvider,
    BRAND_INFO_MAP,
    CUSTOM_ROUTE_PAGE_URL_REG,
    defaultAuthentication,
    defaultDomain,
    DomainProvider,
    getBrand,
    getDepartmentListWithPath,
    getImageFullUrlInApplication,
    LanguageProvider,
    LoginAuthType,
    pageUrlReg,
    UploadManage,
    UploadManageContext,
    useApplicationPreviewType,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { isWechatBrowser } from '@lighthouse/tools'
import type { MantineThemeOverride } from '@mantine/core'
import { MantineProvider } from '@mantine/core'
import i18next from 'i18next'
import { DevTools } from 'jotai-devtools'
import React, { Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { useAsyncRetry, useCss } from 'react-use'
import styled from 'styled-components'

import { fetchApplicationAtom, fetchSyncComponentsAtom, setLanguageAtom, setPreviewAtom } from '@/atoms/application/action'
import { languageAtom } from '@/atoms/application/state'
import { fetchUserAtom, loginAtom } from '@/atoms/auth/action'
import {
    fetchDataSourceDepartmentAtom,
    fetchDataSourceListAtom,
    fetchDataSourceRoleAtom,
    fetchDataSourceUserAtom
} from '@/atoms/dataSource/action'
import { clearExpiresCacheAtom } from '@/atoms/storage/action'
import { useAppLanguageList, useApplication } from '@/hooks/useApplication'
import { useDataSourceDepartments, useDataSourceRoles, useDataSourceUsers } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { getVideoPlayAuth } from '@/services'
import { changeAppleTouchIcon, changeFavicon } from '@/utils/web'

const theme: MantineThemeOverride = {
    colorScheme: 'light',
    colors: {
        blue: [
            'var(--color-app-theme-1)',
            'var(--color-app-theme-2)',
            'var(--color-app-theme-3)',
            'var(--color-app-theme-4)',
            'var(--color-app-theme-5)',
            'var(--color-app-main)',
            'var(--color-app-main)',
            'var(--color-app-main)',
            'var(--color-app-theme-9)',
            'var(--color-app-theme-10)'
        ]
    },
    primaryShade: 6
}

const AppShellScreen = styled.main`
    position: fixed;
    inset: 0;
    box-sizing: border-box;

    /* 兼容 iOS < 11.2 */
    top: constant(safe-area-inset-top);
    left: constant(safe-area-inset-left);
    right: constant(safe-area-inset-right);
    bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS >= 11.2 */
    top: env(safe-area-inset-top);
    left: env(safe-area-inset-left);
    right: env(safe-area-inset-right);
    bottom: env(safe-area-inset-bottom);
`

const MainContent = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`

const appSvg = getAssetUrl('common', 'app.svg')

export const Layout: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null)
    // 发送微信浏览器中微信登录
    const [search] = useSearchParams()
    const wechatCode = search.get('code')
    const wechatState = search.get('state')

    const languageList = useAppLanguageList()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const users = useDataSourceUsers()
    const roles = useDataSourceRoles()
    const departments = useDataSourceDepartments()
    const language = useAtomData(languageAtom)
    const app = useApplication()

    const { run: fetchAppUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceRole } = useAtomAction(fetchDataSourceRoleAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    const { run: fetchSyncComponents } = useAtomAction(fetchSyncComponentsAtom)

    const { run: clearExpiresCache } = useAtomAction(clearExpiresCacheAtom)
    const { run: setPreview } = useAtomAction(setPreviewAtom)
    const { run: fetchApplication } = useAtomAction(fetchApplicationAtom)
    const { run: fetchDataSourceList } = useAtomAction(fetchDataSourceListAtom)
    const { run: setLanguage } = useAtomAction(setLanguageAtom)
    const { run: fetchUser } = useAtomAction(fetchUserAtom)

    const departmentsWidthLabelPath = useMemo(() => getDepartmentListWithPath(departments), [departments])

    const onFetchUserInfo = useCallback(
        async (isJumpReviewPage?: boolean) => {
            const user = await fetchUser()
            const { pathname, href } = window.location
            const normalUrlMatch = pageUrlReg.test(pathname)
            const customPageUrlMath = CUSTOM_ROUTE_PAGE_URL_REG.test(pathname.slice(1))
            const isVisitPage = customPageUrlMath || normalUrlMatch
            const redirectUrl = encodeURIComponent(href)
            const search = isVisitPage ? `?redirect=${redirectUrl}` : ''
            if (user.bindMobile) {
                navigate(`/account/bindMobile${search}`, { replace: true })
                return user
            }
            if (!user.checkPerfect) {
                navigate(`/account/perfect${search}`, { replace: true })
                return user
            }
            if (user.status?.[0] === AppUserStatus.REVIEWED && isJumpReviewPage) {
                Toast.error(t('0011021'))
                navigate('/account/review', { replace: true })
            }
        },
        [fetchUser, navigate, t]
    )

    // 微信登录
    const onWechatLogin = useCallback(
        async (params: { versionId: string; appId: string }) => {
            const { versionId, appId } = params
            if (!wechatCode) {
                return
            }
            if (wechatState === 'merge') {
                const res = await srv.bindAccount({
                    code: wechatCode,
                    type: LoginAuthType.wechat
                })
                if (res) {
                    Toast.success('微信绑定成功', { icon: 'Tick' })
                }
                await onFetchUserInfo()
            }
            if (wechatState === 'login') {
                try {
                    const [isSuccess, errCode] = await srv.login({
                        authType: isWechatBrowser() ? LoginAuthType.wechat : LoginAuthType.pc_wechat,
                        verificationCode: wechatCode,
                        versionId,
                        appId
                    })
                    if (isSuccess) {
                        Toast.success(i18next.t('loginSuccess'))
                        await onFetchUserInfo(true)
                    }
                } catch (error) {
                    console.log('🚀 ~ onWechatLogin ~ error:', JSON.stringify(error))
                }
            }
        },
        [onFetchUserInfo, wechatCode, wechatState]
    )

    const previewType = useApplicationPreviewType()

    useEffect(() => {
        setPreview(previewType)
    }, [previewType, setPreview])

    const { value, loading } = useAsyncRetry(async () => {
        const app = await fetchApplication()
        const {
            type,
            id: appId,
            loggedIn,
            version: { id: versionId }
        } = app
        await setLanguage()
        await fetchDataSourceList({ appId })
        fetchAppUser()
        fetchDataSourceRole()
        fetchDataSourceDepartment()
        fetchSyncComponents()
        // await fetchSystemDataSource({ appId })

        if (type === ApplicationType.website) {
            const { config } = app
            const { webTag } = config
            const { logo = '', title = '', backgroundColor = '#FFFFFF' } = webTag
            // document.title = title || app.name
            changeFavicon(getImageFullUrlInApplication(appId, logo) || appSvg)
            changeAppleTouchIcon({ appId, icon: getImageFullUrlInApplication(appId, logo) || appSvg, title, backgroundColor })
        }

        // 已经登录，但没有登录态时，获取用户信息并准备跳转
        if (loggedIn) {
            onFetchUserInfo()
        }
        // 微信登陆
        await onWechatLogin({
            versionId,
            appId
        })
        return app
    }, [])

    // 清除过期的缓存
    useEffect(() => {
        clearExpiresCache()
    }, [clearExpiresCache])

    const applicationProps = useMemo(
        () => ({
            appId: app?.id,
            personOptions: users,
            roleOptions: roles,
            departmentOptions: departmentsWidthLabelPath,
            isRealityMobile: true,
            isBuilder: false,
            authentication: app?.config.authentication,
            fetchVideoPlayAuth: getVideoPlayAuth
        }),
        [app?.config.authentication, app?.id, departmentsWidthLabelPath, roles, users]
    )

    const languageValue = useMemo(() => ({ language, languageList }), [language, languageList])

    const { themeColor } = BRAND_INFO_MAP[getBrand()]

    const brandTheme = useCss({
        '--color-theme-1': `var(--color-${themeColor}-25)`,
        '--color-theme-2': `var(--color-${themeColor}-50)`,
        '--color-theme-3': `var(--color-${themeColor}-100)`,
        '--color-theme-4': `var(--color-${themeColor}-200)`,
        '--color-theme-5': `var(--color-${themeColor}-300)`,
        '--color-theme-6': `var(--color-${themeColor}-400)`,
        '--color-theme-7': `var(--color-${themeColor}-500)`,
        '--color-theme-8': `var(--color-${themeColor}-600)`,
        '--color-theme-9': `var(--color-${themeColor}-700)`,
        '--color-theme-10': `var(--color-${themeColor}-800)`,
        '--color-main': `var(--color-${themeColor}-500)`
    })

    useEffect(() => {
        document.body.className = brandTheme
        document.body.style.setProperty('--byecode-colors-colorPrimary', `var(--color-${themeColor}-500)`)
        document.body.style.setProperty('--byecode-colors-colorPrimaryHover', `var(--color-${themeColor}-600)`)
    }, [brandTheme, themeColor])

    const appThemeColor = app?.config.theme.palettes.find(item => item.id === app.config.theme.primaryPointer)?.value
    // const customBuiTheme = appThemeColor && createTheme({ colors: { colorPrimary: appThemeColor, colorPrimaryHover: appThemeColor } })

    return (
        <ByecodeUIConfigProvider locale={language}>
            <ByecodeUIThemeProvider colorPrimary={appThemeColor} colorPrimaryHover={appThemeColor}>
                <MantineProvider withGlobalStyles withCSSVariables theme={theme}>
                    <ApplicationProvider value={applicationProps}>
                        <LanguageProvider value={languageValue}>
                            <AuthenticationProvider value={app?.config.authentication ?? defaultAuthentication}>
                                <DomainProvider value={app?.type === ApplicationType.website ? app?.config?.domain : defaultDomain}>
                                    <UploadManageContext>
                                        <ModalsProvider>
                                            <ScannerProvider>
                                                {createPortal(<ToastContainer />, document.body)}
                                                {/* {createPortal(<ModalContainer />, document.body)} */}
                                                {/* {ref.current && createPortal(<ModalContainer />, ref.current)} */}
                                                <AppShellScreen id="app-shell-screen" ref={ref}>
                                                    {import.meta.env.DEV && createPortal(<DevTools />, document.body)}
                                                    <MainContent id="main-content">
                                                        {/* {import.meta.env.DEV && <DevTools />} */}
                                                        <Suspense>
                                                            <AppThemeContextProvider theme={app?.config?.theme}>
                                                                {loading ? <Loading /> : <Outlet />}
                                                                <UploadManage />
                                                            </AppThemeContextProvider>
                                                        </Suspense>
                                                    </MainContent>
                                                </AppShellScreen>
                                            </ScannerProvider>
                                        </ModalsProvider>
                                    </UploadManageContext>
                                </DomainProvider>
                            </AuthenticationProvider>
                        </LanguageProvider>
                    </ApplicationProvider>
                </MantineProvider>
            </ByecodeUIThemeProvider>
        </ByecodeUIConfigProvider>
    )
}
