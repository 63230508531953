import type { BlockAbstract, InputValueItem, PageAbstract, PageMetaData } from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import { getBlockWithMergedBreakPoint, getPageWithMergedBreakPoint, hasChildrenBlock, isContainerBlock } from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { findLast } from 'rambda'

import { languageAtom, previewAtom } from '../application/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'
import { equalPageStack } from '../utils/equalPageStack'

export const pageListAtom = atomWithImmer<PageAbstract[]>([])

export const homePageAtom = atom(get => {
    const list = get(pageListAtom)
    const language = get(languageAtom)
    return list.find(page => page.isHome && page.language === language)
})

export const defaultPageListAtom = atom(get => {
    const list = get(pageListAtom)
    return list.filter(page => page.type === 'default')
})

export const pageAtomFamily = atomFamily((pageID: string) =>
    atom(
        get => {
            const previewType = get(previewAtom)
            const page = get(pageListAtom)?.find(item => item.id === pageID)
            if (!page) {
                return
            }
            return getPageWithMergedBreakPoint(previewType, page)
        },
        (_, set, payload: PageAbstract | undefined | ((draft: Draft<PageAbstract | undefined>) => void)) =>
            set(pageListAtom, draft => {
                const page = draft?.find(item => item.id === pageID)
                applyDraftPayload(page, payload)
            })
    )
)

export const blocksAtom = atomWithImmer<Partial<Record<string, BlockAbstract[]>>>({})

export const transformOriginBlock = (blocks: BlockAbstract[], previewType: ApplicationPreviewEnum): BlockAbstract[] => {
    return blocks.map(block => {
        const newBlock = getBlockWithMergedBreakPoint(previewType, block)
        if (hasChildrenBlock(newBlock)) {
            if (isContainerBlock(newBlock) && newBlock.children) {
                return {
                    ...newBlock,
                    children: newBlock.children.map(item => ({
                        ...item,
                        children: transformOriginBlock(item.children, previewType)
                    }))
                }
            }
            if (newBlock.children && newBlock.children.length > 0) {
                return {
                    ...newBlock,
                    children: transformOriginBlock(newBlock.children as BlockAbstract[], previewType)
                } as BlockAbstract
            }
        }
        return newBlock
    })
}

export const pageBlocksAtom = atomFamily((pageId: string) => {
    return atom(get => {
        const previewType = get(previewAtom)
        const originBlocks = get(blocksAtom)[pageId]
        if (!originBlocks) {
            return []
        }
        return transformOriginBlock(originBlocks, previewType)
    })
}, equal)

export const pageStackAtom = atomWithImmer<PageMetaData[]>([])

export const pageStackAtomFamily = atomFamily(
    ({ pageId, stackId }: { pageId: string; stackId?: string }) =>
        atom(
            get => {
                const stack = get(pageStackAtom)
                return stack.find(equalPageStack(pageId, stackId))
            },
            (_, set, payload: (draft: Draft<PageMetaData>) => void) => {
                set(pageStackAtom, draft => {
                    const pageStack = draft?.find(item => item.stackId === stackId)
                    if (pageStack) {
                        applyDraftPayload(pageStack, payload)
                    }
                })
            }
        ),
    equal
)

export const firstPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stack = get(pageStackAtom)
    return stack[0]
})

export const lastPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stack = get(pageStackAtom)
    return stack[stack.length - 1]
})

export const lastRootPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stackList = get(pageStackAtom)
    return findLast(stack => stack.pageId === stack.rootPageId, stackList)
})

export const pageStackOfFieldBlockAtom = atomWithImmer<Record<string, Record<string, InputValueItem>>>({})

export const pageStackOfFormContainerBlockChangedAtom = atomWithImmer<Record<string, boolean>>({})
