import type { ContainerBlockAbstract } from '@lighthouse/core'
import { type ApplicationAbstract, type ApplicationWebsiteSetting, ApplicationType } from '@lighthouse/core'
import type { StripeAccountItem, WeChatPayAccountItem, WeChatPublicAccountItem, WeChatWebsiteAccountItem } from '@lighthouse/shared'
import { ApplicationPreviewEnum, LANGUAGE_LIST, transformOriginBlock } from '@lighthouse/shared'
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { atomWithProxy } from 'jotai-valtio'
import { proxy } from 'valtio'

import http from '@/http'

import type { FloatStateTree } from './types'

export const previewAtom = atom<ApplicationPreviewEnum>(ApplicationPreviewEnum.desktop)

export const applicationIdProxy = proxy({ id: '' })

export const appAtom = atomWithImmer<
    | (ApplicationAbstract & {
          loggedIn?: boolean
          integrations?: {
              wechatPay?: WeChatPayAccountItem
              weChatOfficialAccount?: WeChatPublicAccountItem
              wechatWebsite?: WeChatWebsiteAccountItem
              stripe?: StripeAccountItem
          }
      })
    | null
>(null)

export const applicationIdProxyAtom = atomWithProxy(applicationIdProxy)

export const currentAppIdAtom = atom(get => get(appAtom)?.id ?? '')

export const currentVersionIdAtom = atom(get => get(appAtom)?.version.id ?? '')

export const currentEnvIdAtom = atom(get => get(appAtom)?.version.envId ?? '')

export const languageAtom = atomWithStorage('language', '', {
    getItem: (key: string) => {
        // 兼容老缓存中lang带双引号
        const language = LANGUAGE_LIST.find(language => localStorage.getItem(key)?.includes(language.lang))?.lang ?? ''
        http.defaults.headers.common['lang'] = language
        return Promise.resolve(language ?? '') ?? ''
    },
    setItem(key, newValue: string) {
        localStorage.setItem(key, newValue ?? '')
        http.defaults.headers.common['lang'] = newValue
        return Promise.resolve()
    },
    removeItem(key) {
        localStorage.removeItem(key)
        return Promise.resolve()
    }
})

export const websiteApplicationSettingAtom = atom<ApplicationWebsiteSetting | null>(get => {
    const app = get(appAtom)
    return app?.type === ApplicationType.website ? app?.config : null
})

export const applicationVersionAtom = atom(get => get(appAtom)?.version)

/** 同步组件 */
export const syncComponentsAtom = atomWithImmer<ContainerBlockAbstract[]>([])

export const pageStackOfFloatBlockAtom = atomWithImmer<FloatStateTree[]>([])

/** 同步组件-断点属性合并 */
export const transformSyncComponentsAtom = atom(get => {
    const previewType = get(previewAtom)
    const originBlocks = get(syncComponentsAtom)
    return transformOriginBlock(originBlocks, previewType)
})
