import { Toast } from '@byecode/ui'
import type { SubFormRecord } from '@lighthouse/block'
import { getFormOfFieldCodeValidator, getFormOfSubFormCodeValidator, PageModuleForm } from '@lighthouse/block'
import { type BlockAbstract, type ButtonAction, type FormContainerBlockAbstract, type InputValueItem, type PageType,EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { ApplicationPreviewEnum, pageStackPubSub, resolveFilter, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { Suspense, useCallback, useMemo } from 'react'

import { setRecordAtom } from '@/atoms/dataSource/action'
import { pageAtomFamily, pageBlocksAtom, pageStackAtomFamily, pageStackOfFieldBlockAtom } from '@/atoms/page/state'
import { actionFlowListAtom } from '@/atoms/workflow/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useAccount } from '@/hooks/useAccount'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppId, useCurrentEnvId, usePreview } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList, useRecord } from '@/hooks/useDataSource'
import { useEventTrigger } from '@/hooks/useEventTrigger'
import { useFieldBlockDefaultValueRender } from '@/hooks/useFieldBlockDefaultValueRender'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { usePageStackActions } from '@/hooks/usePageStackActions'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import { useUserRecord } from '@/hooks/useUserRecord'
import { useWxAuthRoot } from '@/hooks/useWxAuthRoot'
import * as srv from '@/services'

const FormContainerBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.FormContainerBlock })))

interface FormContainerControllerProps {
    blockData: FormContainerBlockAbstract
    node: FlowLayoutNode
    children?: React.ReactNode
}

const FormContainerController = (props: FormContainerControllerProps) => {
    const { blockData, node } = props
    const {
        id: blockId,
        config: { action, rules = [], weChatCollector, pointer, collectEvents }
    } = blockData

    const { isUsed } = weChatCollector ?? {}
    const { handleTriggerEvent } = useEventTrigger()
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const preview = usePreview()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const dataSourceList = useDataSourceList(appId)
    const { record: userRecord } = useUserRecord()
    const user = useAccount()
    const pageRecordDep = useAtomData(pageStackAtomFamily({ pageId, stackId }))
    const { initialRecordValue } = pageRecordDep ?? {}
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const currentRecord = useRecord(appId, envId, pointer, curr.recordId || '')

    const dataSource = useDataSource(appId, envId, pointer)
    const actionFlows = useAtomData(actionFlowListAtom)
    const fieldBlockValueMap = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )
    useWxAuthRoot(isUsed)

    const { handleActionTrigger } = useActionTrigger(blockId)
    const { peekCurrentPageStack, closeCurrentPageLayer } = usePageStackActions()
    const { run: updateRecord } = useAtomAction(setRecordAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtomFamily({ pageId, stackId }))

    const pageType = useAtomData(
        pageAtomFamily(pageId),
        React.useCallback(s => s?.type, [])
    )
    const blocks = useAtomData(pageBlocksAtom(pageId))

    const reqRules = useMemo(
        () =>
            rules.map(rule => {
                const { filter, checkFilter } = rule
                return {
                    ...rule,
                    filter: resolveFilter({
                        filter,
                        disableResolvedPageData: true,
                        extraParams: {
                            pageStackFormState: fieldBlockValueMap
                        }
                    }),
                    checkFilter: resolveFilter({
                        filter: checkFilter,
                        disableResolvedPageData: true,
                        extraParams: {
                            pageStackFormState: fieldBlockValueMap
                        }
                    })
                }
            }),
        [fieldBlockValueMap, rules]
    )

    const { handleRenderTitle } = useRichTextToTitle()

    const handleClickTriggerEvent = useCallback((blockName: string) => {
        handleTriggerEvent(collectEvents, EVENT_VARIABLE_TYPE.BUTTON_CLICK, { blockName })
    }, [collectEvents, handleTriggerEvent])

    const onFieldBlockDefaultValueRender = useFieldBlockDefaultValueRender(pointer, initialRecordValue)

    // 表单提交-start
    const handleSubmit = useCallback(
        async (params: {
            blockId: string
            pointer: string
            value: InputValueItem[]
            currentRecordId?: string
            parentRecordId?: string
            action?: ButtonAction
            subFormRecord?: Record<string, SubFormRecord[]>
        }) => {
            const { blockId, pointer, value, currentRecordId, action, parentRecordId, subFormRecord } = params
            const fields = value.map(({ fieldId, value, id: blockId }) => ({ fieldId, value, blockId }))
            const subFormRecords = Object.entries(subFormRecord ?? {}).reduce<SubFormRecord[]>((pre, cur) => [...pre, ...cur[1]], [])
            const res = await srv.submitForm({
                appId,
                envId,
                pageId,
                blockId,
                dsId: pointer,
                fields,
                currentRecordId,
                parentRecordId,
                validator: {
                    rules: reqRules,
                    codeValidator: {
                        ...getFormOfFieldCodeValidator(value, blocks),
                        ...getFormOfSubFormCodeValidator(subFormRecords, blocks)
                    }
                },
                subFormRecord: Object.entries(subFormRecord ?? {}).map(([id, records]) => ({
                    subFormId: id,
                    subFormContent: records.map(record => ({
                        recordId: record.id,
                        columns: Object.entries(record.content).map(([id, item]) => ({ columnId: id, value: item.value }))
                    }))
                }))
            })
            const { record, repeatFieldIds, failureMessage } = res

            if (failureMessage) {
                Toast.error(failureMessage)
                return res
            }
            if (!record) {
                return res
            }

            // 获取动作执行前的当前页面栈
            const prevPageStack = peekCurrentPageStack()

            if (record && action) {
                await handleActionTrigger(action, { formRecord: record })
            }

            // 获取动作执行后的当前页面栈
            // 2023-11-07 13:57:04 灿白说去掉提交成功的提示，表单提交后动作提示
            const currentPageStack = peekCurrentPageStack()
            const isWechatClose = action?.type === 'wechatPay' ? user.userId && action.data.wechatPay.operationType === 'REFUND' : true
            // 检测动作流节点中，是否有打开页面的动作，如果有，则不关闭当前页面
            const currentFlow = action?.type === 'flow' && actionFlows?.find(flow => flow.id === action?.data?.flow?.flowId)
            const actionContainsOpenXPage =
                currentFlow && currentFlow?.content?.nodes?.some(node => /OPEN(.*)PAGE/.test(node.data.nodeType ?? ''))
            if (
                !/open(.*)Page/.test(action?.type ?? '') &&
                !actionContainsOpenXPage &&
                (pageType === 'creator' || pageType === 'edit') &&
                (currentPageStack?.openType === 'modal' || currentPageStack?.openType === 'drawer') &&
                isWechatClose
            ) {
                closeCurrentPageLayer()
            }
            // 如果在动作执行过程中，当前页面栈没有发生变化，则关闭当前页面，否则不关闭，因为动作执行过程打开了新页面
            // if (
            //     prevPageStack?.pageId === currentPageStack?.pageId &&
            //     (pageType === 'creator' || pageType === 'edit') &&
            //     (currentPageStack?.openType === 'modal' || currentPageStack?.openType === 'drawer') &&
            //     isWechatClose
            // ) {
            //     closeCurrentPageLayer()
            // }

            setPageStack(draft => {
                if (!draft.blockRuntimeState.formContainer) {
                    draft.blockRuntimeState.formContainer = {}
                }
                draft.blockRuntimeState.formContainer = {}
            })

            // 更新操作也需要，因为有关联或者 filter 的场景
            pageStackPubSub.emit(`${pointer}-ADD`, record)

            // @todo 张琪根据内存相应调整
            if (pageType === 'edit') {
                const { appId, dsId, id: recordId } = record
                updateRecord({ appId, envId, dsId, recordId, record })
                return res
            }
            return res
        },
        [
            appId,
            envId,
            pageId,
            reqRules,
            blocks,
            peekCurrentPageStack,
            user.userId,
            actionFlows,
            pageType,
            setPageStack,
            handleActionTrigger,
            closeCurrentPageLayer,
            updateRecord
        ]
    )
    // 表单提交-end

    return (
        <PageModuleForm
            node={node}
            block={blockData}
            blocks={blocks}
            pageType={pageType as PageType}
            userRecord={userRecord}
            isMobile={preview === ApplicationPreviewEnum.mobile}
            dataSourceList={dataSourceList}
            onVariableValueRender={onFieldBlockDefaultValueRender}
            dataSource={dataSource}
            record={currentRecord}
            onSubmit={(val, pointer, subFormRecordMap) =>
                handleSubmit?.({
                    blockId,
                    pointer,
                    value: val,
                    currentRecordId: curr.recordId,
                    parentRecordId: prev.recordId,
                    action,
                    subFormRecord: subFormRecordMap
                })
            }
            onRenderTitle={handleRenderTitle}
            onTriggerEvent={handleClickTriggerEvent}
        >
            <Suspense fallback={<div />}>
                <FormContainerBlock {...props} />
            </Suspense>
        </PageModuleForm>
    )
}

export default FormContainerController
