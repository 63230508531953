import { useCustomViewBlockContext } from "@lighthouse/block";
import type { CollectEvent, EVENT_VARIABLE_TYPE, RecordLikeProtocol } from "@lighthouse/core";
import { CollectEventWriteType } from "@lighthouse/core";
import type { ResolvedVariableExtraParams, TriggerEventExtraOption } from "@lighthouse/shared";
import { ANONYMOUS, getCreateRecordVariableValue, getHandleSubProcessVariableValue, getWindowUrl, useAtomData, validateEvent } from "@lighthouse/shared";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useMemo } from "react";

import { lastPageOfStackAtom, pageStackOfFieldBlockAtom } from "@/atoms/page/state";
import { useCurrentPageContext, useCurrentStackIdContext } from "@/context/PageContext";
import * as srv from '@/services';

import { useAccount } from "./useAccount";
import { useCurrentAppId, useCurrentEnvId } from "./useApplication";
import { useDataSourceList, useRecord } from "./useDataSource";
import { usePageDataSourceForVariableSelector } from "./usePage";
import { useUserRecord } from "./useUserRecord";

export const handleCollectData = (collectEvent: CollectEvent, excParams: ResolvedVariableExtraParams) => {
    const { currentAppId } = excParams.clickTriggerNodeParams

    const isValidate = validateEvent(collectEvent)
    if(!isValidate){
        return
    }
    // const { writeType, triggerType } = config
    if (collectEvent.writeType === CollectEventWriteType.RECORD) {
        const { record: collectEventRecord, writeType } = collectEvent
        if (!collectEventRecord || !collectEventRecord.dataSourceId) {
            return
        }
        const config = {
            dataSourceId: collectEventRecord.dataSourceId,
            fields: collectEventRecord.fields
        }
        const fieldValues = getCreateRecordVariableValue(config, excParams)
        srv.eventCollect({
            appId: currentAppId,
            writeType,
            dsId: collectEventRecord.dataSourceId,
            fieldValues,
        })
    }


    if (collectEvent.writeType === CollectEventWriteType.WORKFLOW) {
        const { workflow, writeType } = collectEvent
        if (!workflow || !workflow.subProcessId) {
            return
        }
        const params = getHandleSubProcessVariableValue(workflow, excParams)
        // const record = await srv.findSingleRecord(params)
        srv.eventCollect({
            appId: currentAppId,
            writeType,
            subProcessId: workflow.subProcessId,
            args: params,
        })
    }
}




export const useEventTrigger = (options?: {
    formRecord?: RecordLikeProtocol
    viewRecord?: RecordLikeProtocol
}) => {
    const { formRecord, viewRecord } = options ?? {}
    // 获取当前用户
    const currentUser = useAccount()
    const currentStackId = useCurrentStackIdContext()
    const currentUserId = currentUser?.userId || ANONYMOUS
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const userRecord = useUserRecord()
    const getCurrentPageDepsAtomCallback = useAtomCallback(
        useCallback(get => {
            return get(lastPageOfStackAtom)
        }, [])
    )
    const dataSourceList = useDataSourceList(appId)
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const pageStackFormState = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s[currentStackId], [currentStackId])
    )
    const pageRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const { record: customViewRecord } = useCustomViewBlockContext()


    const usedViewRecord = customViewRecord || viewRecord

    const extraParams = useMemo(() => ({
        pageRecord,
        dataSourceList,
        viewRecord: usedViewRecord,
        userRecord,
        formRecord,
        getCurrentPageDeps: getCurrentPageDepsAtomCallback,
        getCurrentPageLink: () => getWindowUrl(),
        pageStackFormState,
        clickTriggerNodeParams: {
            currentUserId,
            prevRecord,
            currentAppId: appId,
            currentEnvId: envId,
            currentPageId: pageId
        }
    }), [appId, currentUserId, dataSourceList, envId, formRecord, getCurrentPageDepsAtomCallback, pageId, pageRecord, pageStackFormState, prevRecord, usedViewRecord, userRecord])

    const handleTriggerEvent = useCallback((collectEvents: CollectEvent[] | undefined, type: EVENT_VARIABLE_TYPE, options?: TriggerEventExtraOption) => {
        if (!collectEvents || collectEvents.length === 0) {
            return
        }
        const { blockName, pageName } = options ?? {}
        const typeEvents = collectEvents.filter(event => event.enable && event.triggerType === type)
        typeEvents.forEach(item => {
            const eventName = item.title
            handleCollectData(item, { ...extraParams, blockName, pageName, event: { eventName, eventType: type } } as ResolvedVariableExtraParams)
        })
    }, [extraParams])

    return { handleTriggerEvent }

}
