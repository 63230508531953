import { closeModal, IconFont, openModal } from '@byecode/ui'
import { Tabs } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

// import { PAGE_CONTAINER_HOST } from '../../constants/common'
import type { MessageEvents, MessageListDataRes, MessageType } from '../../types'
import { NotificationDetail } from '../NotificationDetail'
import { NotificationList } from './NotificationList'

interface NotificationPanelProps {
    appId?: string
    device?: 'mobile' | 'desktop'
    messageEvents?: MessageEvents
    onClose?: () => void
}

type TabType = 'all' | 'stationMessage'

const SCxNotificationPanelWrapper = styled.div`
    height: calc(100vh - 54px);
`

const SCxNotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    border-bottom: 1px solid var(--color-gray-100);
`

const SCxNotificationHeaderTitle = styled.div`
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
`

const SCxIconButton = styled(IconFont)`
    color: var(--color-gray-500);
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxNotificationHeaderActionWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const NotificationPanel: React.FC<NotificationPanelProps> = ({ messageEvents, appId, device, onClose }) => {
    const [tab, setTab] = useState<TabType>('all')
    const [data, setData] = useState<MessageListDataRes>()
    // const { data } = useSWR('listMsg', srv.listMessage)
    const { count, messages } = data ?? {}
    const handleOpenFullScreen = useCallback(
        (id?: string, auditStatus?: 'agree' | 'reject') => {
            onClose?.()
            openModal({
                id: 'notification-detail',
                withCloseIcon: false,
                styles: {
                    desktop: {
                        header: { display: 'none' },
                        modal: {
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            height: '80%'
                        },
                        body: {
                            flex: 1,
                            position: 'relative',
                            overflow: 'hidden'
                        }
                    },
                    mobile: {
                        body: { padding: 0 }
                    }
                },
                // target: PAGE_CONTAINER_HOST,
                children: (
                    <NotificationDetail
                        appId={appId}
                        auditStatus={auditStatus}
                        device={device}
                        messageId={id}
                        data={messages}
                        onClose={() => closeModal('notification-detail')}
                        messageEvents={messageEvents}
                    />
                )
            })
        },
        [appId, device, messageEvents, messages, onClose]
    )

    const getCount = useCallback(
        (type?: MessageType) => {
            switch (type) {
                case 'STATION_MESSAGE': {
                    return messages?.filter(item => item.type === 'STATION_MESSAGE').length
                }
                default: {
                    return count
                }
            }
        },
        [count, messages]
    )

    const fetchMessages = useCallback(async () => {
        if (messageEvents?.onFetchMessages) {
            const { onFetchMessages } = messageEvents
            const messages = await onFetchMessages()
            setData(messages)
        }
    }, [messageEvents])

    useEffect(() => {
        fetchMessages()
    }, [fetchMessages])

    const panelContent = useMemo(() => {
        switch (tab) {
            case 'all': {
                return <NotificationList data={messages} onNavigateTo={handleOpenFullScreen} />
            }
            case 'stationMessage': {
                const data = messages?.filter(item => item.type === 'STATION_MESSAGE')
                return <NotificationList data={data} onNavigateTo={handleOpenFullScreen} />
            }
            default: {
                return null
            }
        }
    }, [handleOpenFullScreen, messages, tab])

    return (
        <SCxNotificationPanelWrapper>
            <SCxNotificationHeader>
                <SCxNotificationHeaderTitle />
                <SCxNotificationHeaderActionWrapper>
                    <SCxIconButton type="Open" style={{ marginRight: 12 }} onClick={() => handleOpenFullScreen()} />
                    <SCxIconButton type="Close" onClick={onClose} />
                </SCxNotificationHeaderActionWrapper>
            </SCxNotificationHeader>
            {panelContent}
        </SCxNotificationPanelWrapper>
    )
}
