import { Message, Toast } from '@byecode/ui'
import { useContainerBlockContext, useCustomViewBlockContext } from '@lighthouse/block'
import { type ButtonAction, type RecordLikeProtocol, VariableType, WechatPayType } from '@lighthouse/core'
import type { IClickActionConfig, UpdateRecordActionConfig } from '@lighthouse/shared'
import {
    ANONYMOUS,
    blockListenerSystem,
    commonPages,
    downloadFile,
    dummyTriggerNodeId,
    generateVariableValue,
    getActiveAddIClickUserVariableValue,
    getCreateRecordVariableValue,
    getMainTableRecordId,
    getSearchParams,
    getUpdateRecordVariableValue,
    getWechatPayType,
    getWindowUrl,
    isIdsValue,
    openNewBrowserPage,
    pageStackPubSub,
    resolveDownloadFileUrls,
    resolvedUpstreamVariableValue,
    resolveFilter,
    resolveVariable,
    scroll2FlowNode,
    useAtomData,
    useFillPickerContext,
    validateFilterFieldData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import copyToClipboard from 'copy-to-clipboard'
import { useAtomCallback } from 'jotai/utils'
import { find } from 'rambda'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { actionWorker } from '@/actionsEngine'
import { currentAppIdAtom, currentEnvIdAtom } from '@/atoms/application/state'
import { userDataAtom } from '@/atoms/auth/state'
import { recordAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageBlocksAtom, pageStackAtomFamily, pageStackOfFieldBlockAtom } from '@/atoms/page/state'
import { workflowListAtom } from '@/atoms/workflow/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import * as srv from '@/services'
import { type StripePaySearch, type WechatPaySearch, PayTriggerType } from '@/services/types'

// import { openWechatAuthModal } from '@/utils'
import { useAccount } from './useAccount'
import { useActionPay } from './useActionPay'
import { useActionRuntimeParams } from './useActionRuntimeParams'
import { usePageList, usePreview } from './useApplication'
import { useDataSourceList, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'
import { useUserRecord } from './useUserRecord'
import { sendWxRoot } from './useWxAuthRoot'

export const useActionTrigger = (blockId: string) => {
    const workflows = useAtomData(workflowListAtom)
    const currentAppId = useAtomData(currentAppIdAtom)
    const envId = useAtomData(currentEnvIdAtom)
    const { pageId } = useCurrentPageContext()
    const { palettes } = useFillPickerContext()
    const { scope } = useContainerBlockContext()
    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    // 所有数据源
    const dataSourceList = useDataSourceList(currentAppId)
    // 所有页面
    const pageList = usePageList()
    // 用户数据源，当前用户数据
    const userRecord = useUserRecord()
    const user = useAccount()
    const { record: customViewRecord } = useCustomViewBlockContext()
    const previewType = usePreview()

    const navigate = useNavigate()
    const currentPage = useCurrentPageContext()
    const currentStackId = useCurrentStackIdContext()
    const pageStackAtom = pageStackAtomFamily({ stackId: currentStackId, pageId: currentPage?.pageId })

    const { currentPageId } = useAtomData(
        pageStackAtom,
        useCallback(s => ({ currentPageId: s?.pageId ?? '' }), [])
    )
    const pageStackFormState = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s[currentStackId], [currentStackId])
    )
    const {
        curr: { recordId: currentRecordId, datasource: currentDataSource },
        prev: { recordId: prevRecordId, datasource: prevDataSource }
    } = usePageDataSourceForVariableSelector({ pageId: currentPageId, stackId: currentStackId })
    const pageRecord = useRecord(currentAppId, envId, currentDataSource?.id ?? '', currentRecordId ?? '')

    const prevRecord = useRecord(currentAppId, envId, prevDataSource?.id ?? '', prevRecordId ?? '')

    useActionPay()
    const actionRuntimeParams = useActionRuntimeParams()

    const sequenceSingleWorker = useCallback(
        (action: ButtonAction) => {
            const { type, data } = action
            if (type === 'flow') {
                const flowData = workflows.find(w => w.id === data.flow.flowId)
                if (flowData) {
                    actionWorker.sequenceWorker(flowData)
                }
            }
        },
        [workflows]
    )

    const getClickTriggerNodeParams = useAtomCallback(
        useCallback(
            (get, set, params?: { record?: RecordLikeProtocol; prevRecord?: RecordLikeProtocol; appId: string; dsId: string }) => {
                const currentAppId = get(currentAppIdAtom)
                const record = params?.record
                // 实现当前页面所在记录，也就是触发节点逻辑的记录
                const currentPageMeta = get(lastPageOfStackAtom)

                const appId = currentPageMeta?.appId ?? params?.appId ?? ''
                const dsId = currentPageMeta?.dsId ?? params?.dsId ?? ''
                const recordId = currentPageMeta?.recordId ?? ''

                const currentRecord = record ?? get(recordAtomFamily({ appId, envId, dsId, recordId }))

                // 获取当前用户
                const currentUserId = get(userDataAtom)?.userId || ANONYMOUS

                return {
                    clickTriggerNodeParams: {
                        record: currentRecord,
                        prevRecord: params?.prevRecord,
                        currentAppId,
                        currentEnvId: envId,
                        currentUserId,
                        currentPageId: currentPageMeta?.pageId ?? ''
                    }
                }
            },
            [envId]
        )
    )

    // 导航栏点击回调
    const handleActionTrigger = useCallback(
        async (
            action: ButtonAction,
            options?: {
                formRecord?: RecordLikeProtocol
                viewRecord?: RecordLikeProtocol
            }
        ): Promise<boolean | undefined> => {
            const invokeId = nanoid()
            const isFlowAction = action.type === 'flow'
            const isAutomationAction = action.type === 'automation'

            const { formRecord, viewRecord } = options ?? {}

            const initialRecord = formRecord || customViewRecord || viewRecord
            const usedViewRecord = customViewRecord || viewRecord

            const clickTriggerNodeDepsParams = initialRecord
                ? { record: initialRecord, prevRecord, appId: initialRecord.appId, envId: initialRecord.envId, dsId: initialRecord.dsId }
                : { prevRecord, appId: currentAppId, dsId: prevRecord?.dsId ?? '' }

            const clickTriggerNodeParams = getClickTriggerNodeParams(clickTriggerNodeDepsParams)

            const { record } = clickTriggerNodeParams.clickTriggerNodeParams

            if (isFlowAction) {
                const { data } = action
                const flowData = workflows.find(w => w.id === data.flow.flowId)

                // 必须要启用才可以执行
                if (!flowData?.enabled) {
                    return
                }
                if (!flowData) {
                    return
                }

                sequenceSingleWorker(action)

                actionWorker.injectRuntimeParams(invokeId, flowData, {
                    appId: currentAppId,
                    workflowId: flowData.id,
                    dataSourceList,
                    pageList,
                    userRecord,
                    formRecord,
                    viewRecord: usedViewRecord,
                    pageRecord,
                    getCurrentPageLink: () => getWindowUrl(),
                    pageStackFormState,
                    nodes: flowData.content?.nodes ?? [],
                    palettes,
                    ...actionRuntimeParams,
                    ...clickTriggerNodeParams
                })
                actionWorker.invoke(flowData.id, invokeId)
                return
            }
            if (isAutomationAction) {
                const { data } = action
                const flowData = workflows.find(w => w.id === data.automation.flowId)

                if (!flowData) {
                    return
                }

                // 必须要启用才可以执行
                if (!flowData?.enabled) {
                    Toast.error('执行失败，因为工作流未启用')
                    return
                }
                // 执行
                try {
                    const recordId = record?.id ? getMainTableRecordId(record.id) : ''
                    const success = await srv.triggerAutomationAction({
                        flowId: flowData.id,
                        recordId,
                        pageId
                    })
                    if (success) {
                        Toast.success('执行成功')
                    } else {
                        Toast.error('执行失败')
                    }
                } catch {
                    Toast.error('执行失败')
                }
                return
            }
            const { type, data, id: actionId } = action
            const dummyTriggerNodeParams = {
                appId: currentAppId,
                userRecord,
                pageList,
                pageStackFormState,
                formRecord,
                viewRecord: usedViewRecord,
                ...clickTriggerNodeParams,
                [dummyTriggerNodeId]: {
                    record
                },
                pageRecord,
                getCurrentPageLink: () => getWindowUrl(),
                dataSourceList
            }
            switch (type) {
                case 'openPage': {
                    const params = data[type]
                    const isHaveNoneParam = !params.openPageUrl
                    if (isHaveNoneParam) {
                        return
                    }
                    const { openPageUrl, pageOpenType } = params
                    // const openType = params.pageOpenType
                    // const linkPage = find(pages => pages.id === pageId, pageList)

                    if (commonPages.includes(openPageUrl)) {
                        navigate({ pathname: openPageUrl })
                        break
                    }
                    const rId = getMainTableRecordId(record?.id)
                    actionRuntimeParams.actionEvents.openPage({
                        pageId: openPageUrl,
                        openType: pageOpenType,
                        appId: record?.appId,
                        dsId: record?.dsId,
                        recordId: rId
                        // onSaveCallback: () => {
                        //     actionRuntimeParams.actionEvents.refreshPage?.()
                        // }
                    })
                    break
                }
                case 'openLink': {
                    const params = data[type]
                    const { target, link } = params
                    const linkUrl = link
                    if (!linkUrl) {
                        return
                    }
                    const url = generateVariableValue({ innerType: 'TEXT', jsonContent: linkUrl, extraParams: dummyTriggerNodeParams })

                    if (!url) {
                        break
                    }

                    const realUrl = Array.isArray(url) ? '' : String(url)
                    const isWithProtocol = realUrl.startsWith('http://') || realUrl.startsWith('https://')
                    const withProtocolUrl = isWithProtocol ? realUrl : `https://${realUrl}`
                    // window.open(withProtocolUrl, '_blank')
                    openNewBrowserPage(withProtocolUrl, target)
                    break
                }
                case 'notification': {
                    const params = data[type]
                    if (!params.notificationContent || !params.notificationStatus) {
                        return
                    }
                    const { notificationStatus, notificationContent } = params
                    const toastType = notificationStatus.toLocaleLowerCase() as 'info' | 'error' | 'success'
                    const variableValue = generateVariableValue({
                        innerType: 'TEXT',
                        jsonContent: notificationContent,
                        extraParams: dummyTriggerNodeParams
                    })
                    Message[toastType]({
                        title: String(variableValue),
                        device: previewType
                    })
                    break
                }
                case 'goBack': {
                    actionRuntimeParams.actionEvents.closePage()
                    break
                }
                case 'copy': {
                    const params = data[type]
                    const { copyText } = params
                    const text = generateVariableValue({ innerType: 'TEXT', jsonContent: copyText, extraParams: dummyTriggerNodeParams })
                    if (text) {
                        copyToClipboard(Array.isArray(text) ? '' : String(text))
                        Toast.success('复制成功')
                    }
                    break
                }
                case 'createRecord': {
                    const params = data[type]
                    const { dataSourceId, fields } = params
                    const fieldsValue = getCreateRecordVariableValue({ dataSourceId, fields }, dummyTriggerNodeParams)

                    const record = await srv.createRecordWithFields({
                        appId: currentAppId,
                        envId,
                        dsId: dataSourceId,
                        fields: fieldsValue,
                        pageId
                    })
                    Toast.success('数据创建成功')
                    break
                }
                case 'updateRecord': {
                    const params = data[type]
                    const updateRecordParams = params as UpdateRecordActionConfig
                    const { selectType } = updateRecordParams

                    if (selectType !== 'CURRENT_RECORD') {
                        break
                    }
                    const dsId = record?.dsId
                    const recordId = record?.id ?? ''
                    if (!dsId) {
                        break
                    }
                    const { fields } = getUpdateRecordVariableValue(dsId, params as UpdateRecordActionConfig, dummyTriggerNodeParams)
                    const newRecord = await actionRuntimeParams.actionEvents.updateRecord({
                        appId: currentAppId,
                        envId,
                        dsId,
                        recordId,
                        fields,
                        pageId
                    })

                    // 更新行以后，需要通知对应订阅更新数据
                    pageStackPubSub.emit(`${dsId}-ADD`, newRecord)

                    Toast.success('数据更新成功')
                    break
                }
                case 'openFormPage': {
                    const params = data[type]
                    const { formPageId, pageOpenType, dataSourceId } = params

                    if (!formPageId) {
                        break
                    }
                    const page = pageList.find(item => item.id === formPageId)
                    if (!page || !page.dsId || page.dsId !== dataSourceId) {
                        return
                    }
                    actionRuntimeParams.actionEvents.openPage({
                        pageId: formPageId,
                        openType: pageOpenType,
                        appId: record?.appId,
                        dsId: record?.dsId,
                        recordId: record?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'openRecordPage': {
                    const params = data[type]
                    const { recordPageId, dataSourceId = '', filter, pageOpenType, selectedRecordType } = params

                    if (!recordPageId) {
                        return
                    }

                    const page = pageList.find(item => item.id === recordPageId)
                    if (!page || !page.dsId) {
                        return
                    }

                    let depRecord = { ...record, id: getMainTableRecordId(record?.id) }

                    if (selectedRecordType === 'CURRENT_RECORD' && page.dsId !== depRecord.dsId) {
                        return
                    }

                    if (selectedRecordType === 'MATCHED_RECORD' /*  && filter?.expression?.conditions?.length */) {
                        if (page.dsId !== dataSourceId) {
                            return
                        }
                        const dataSource = find(item => item.id === dataSourceId, dataSourceList)
                        if (!dataSource) {
                            return
                        }
                        const validatedFilter = validateFilterFieldData(filter, dataSource)
                        const resolvedFilter = resolveFilter({ filter: validatedFilter, extraParams: dummyTriggerNodeParams })
                        // 兜底数据
                        const dsId = selectedRecordType === 'MATCHED_RECORD' ? dataSourceId : '' // 动作不会有 UPSTREAM_RECORD
                        depRecord = { ...record, id: getMainTableRecordId(record?.id), dsId, appId: currentAppId }

                        // 为了过滤掉空的条件，处理变量值找不到的场景
                        // if (resolvedFilter?.expression?.conditions?.length !== 0) {
                        const data = await srv.getDs({
                            appId: record?.appId ?? currentAppId,
                            envId: record?.envId ?? envId,
                            dsId,
                            pagination: { currentPage: 1, pageSize: 1 },
                            filter: resolvedFilter,
                            pageId
                        })
                        if (!data) {
                            return
                        }
                        const { records } = data

                        const resRecord = records[0]
                        depRecord = resRecord
                        // }
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId: recordPageId,
                        openType: pageOpenType,
                        appId: depRecord?.appId,
                        dsId: depRecord?.dsId,
                        recordId: depRecord?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'openRecordEditPage': {
                    const params = data[type]
                    const { recordEditPageId, dataSourceId = '', filter, pageOpenType, selectedRecordType } = params

                    if (!recordEditPageId) {
                        return
                    }

                    let depRecord = { ...record, id: getMainTableRecordId(record?.id) }

                    const page = pageList.find(item => item.id === recordEditPageId)
                    if (!page || !page.dsId) {
                        return
                    }

                    if (selectedRecordType === 'CURRENT_RECORD' && page.dsId !== depRecord.dsId) {
                        return
                    }

                    if (selectedRecordType === 'MATCHED_RECORD' /*  && filter?.expression?.conditions?.length */) {
                        if (page.dsId !== dataSourceId) {
                            return
                        }
                        const resolvedFilter = resolveFilter({ filter, extraParams: dummyTriggerNodeParams })

                        // 兜底数据
                        const dsId = dataSourceId // 动作不会有 UPSTREAM_RECORD
                        depRecord = { ...record, id: getMainTableRecordId(record?.id), dsId, appId: currentAppId }

                        // 为了过滤掉空的条件，处理变量值找不到的场景
                        // if (resolvedFilter?.expression?.conditions?.length !== 0) {
                        const data = await srv.getDs({
                            appId: record?.appId ?? currentAppId,
                            envId: record?.envId ?? envId,
                            dsId,
                            pagination: { currentPage: 1, pageSize: 1 },
                            filter: resolvedFilter,
                            pageId
                        })

                        if (!data) {
                            return
                        }
                        const { records } = data

                        depRecord = records[0]
                        // }
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId: recordEditPageId,
                        openType: pageOpenType,
                        appId: depRecord?.appId,
                        dsId: depRecord?.dsId,
                        recordId: depRecord?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'deleteRecord': {
                    const pageId = actionRuntimeParams.getCurrentPageDeps()?.pageId ?? ''
                    const manualDeps = record ? { appId: record.appId, dsId: record.dsId, pageId, recordId: record.id } : undefined
                    const isDeleted = actionRuntimeParams.actionEvents.deleteRecord(manualDeps)

                    if (!isDeleted) {
                        return
                    }

                    const currentPageStack = actionRuntimeParams.actionEvents.peekCurrentPageStack()
                    if (currentPageStack?.openType === 'drawer' || currentPageStack?.openType === 'modal') {
                        actionRuntimeParams.actionEvents.closeCurrentPageLayer()
                    }
                    break
                }
                case 'iClick': {
                    const params = data[type]
                    const addIClickUserParams = getActiveAddIClickUserVariableValue(params as IClickActionConfig, dummyTriggerNodeParams)

                    if (!addIClickUserParams) {
                        break
                    }

                    srv.activeAddIClickUser({ ...addIClickUserParams, pageId })

                    break
                }
                case 'downloadFile': {
                    const params = data[type]
                    const downloadFileParams = resolveDownloadFileUrls(params, dummyTriggerNodeParams)

                    if (downloadFileParams) {
                        await downloadFile(downloadFileParams)
                    }

                    break
                }
                case 'call': {
                    const params = data[type]
                    const phone = resolveVariable(params.phone, dummyTriggerNodeParams)
                    if (typeof phone === 'string') {
                        const a = document.createElement('a')
                        a.href = `tel:${phone}`
                        a.click()
                        a.remove()
                    }

                    break
                }
                case 'wechatPay': {
                    const params = data[type]
                    const { record } = dummyTriggerNodeParams[dummyTriggerNodeId]
                    if (!record || !pageId) {
                        return
                    }
                    const { operationType, payConfig } = params
                    const redirect = window.location.href
                    if (!user.userId) {
                        window.location.href = `/account/login?redirect=${encodeURIComponent(redirect)}`
                        return
                    }
                    const weChatPayType = getWechatPayType()
                    // 微信支付
                    if (operationType === 'PAY') {
                        if (weChatPayType === WechatPayType.JSAPI) {
                            const isPermissionGranted = await srv.checkWxOfficialRoot()
                            if (!isPermissionGranted) {
                                // openWechatAuthModal(() => {
                                sendWxRoot()
                                // })
                                return
                            }
                        }
                        // const { amountFieldId, succeedFieldSettings, descFieldId, callbackWorkflow } = payConfig
                        if (!record) {
                            return
                        }

                        const res = await srv.invokeWechatPay({
                            scene: {
                                type: PayTriggerType.INDEPENDENT,
                                independentConfig: {
                                    blockId,
                                    actionId
                                }
                            },
                            dsId: record.dsId,
                            pageId,
                            payType: weChatPayType,
                            recordId: record.id
                        })
                        if (!res) {
                            break
                        }
                        const { outTradeNo, jsApiResponse, nativeResponse } = res
                        // const regArr = window.location.pathname.match(PAGE_URL_REG)
                        // const pageUrl = !regArr || regArr.length === 1 ? window.location.pathname : `/${regArr.slice(0, -1).join('/')}`
                        // 表单使用数据时计算关闭当前页面后的路径
                        // const redirect = isFormAction ? `${window.location.origin}${pageUrl}` : window.location.href

                        switch (weChatPayType) {
                            case WechatPayType.H5: {
                                break
                            }
                            case WechatPayType.JSAPI: {
                                const { appId, timeStamp, nonceStr, paySign, packageVal, signType } = jsApiResponse
                                const newSearch: WechatPaySearch = {
                                    appId,
                                    timeStamp: timeStamp.toString(),
                                    nonceStr,
                                    paySign,
                                    packageVal,
                                    signType,
                                    pageId,
                                    outTradeNo,
                                    autoRedirectAfterPayment: 1,
                                    redirect: encodeURIComponent(redirect)
                                }
                                const search = getSearchParams(newSearch)
                                navigate(`/pay/?${search}`)
                                break
                            }
                            case WechatPayType.NATIVE: {
                                const { codeUrl } = nativeResponse
                                const newSearch: WechatPaySearch = {
                                    pageId,
                                    outTradeNo,
                                    codeUrl,
                                    autoRedirectAfterPayment: 1,
                                    redirect: encodeURIComponent(redirect)
                                }
                                const search = getSearchParams(newSearch)
                                navigate(`/pay/?${search}`)
                                break
                            }
                            default: {
                                break
                            }
                        }
                    }

                    break
                }
                case 'wechatTemplateMsg': {
                    const params = data[type]
                    const { templateId, personList: configAddresseeIds, values, url } = params
                    const persons = resolvedUpstreamVariableValue({ data: configAddresseeIds, extraParams: dummyTriggerNodeParams })
                    const personListValues = persons.reduce<string[]>((prev, cur) => {
                        if (cur.type === VariableType.VALUE) {
                            const val = cur.valueVariable?.value
                            if (val && isIdsValue(val)) {
                                val.forEach(v => {
                                    prev.push(v)
                                })
                            }
                        }
                        return prev
                    }, [])
                    const addresseeIds = [...new Set(personListValues.flat())]
                    const valuesValues = values.map(value => ({
                        variableName: value.variableName,
                        value: generateVariableValue({
                            innerType: 'TEXT',
                            jsonContent: value.value,
                            extraParams: dummyTriggerNodeParams,
                            format: {
                                dateFormat: true,
                                fileFormat: true
                            }
                        }) as string
                    }))
                    const urlValue = generateVariableValue({
                        innerType: 'TEXT',
                        jsonContent: url,
                        extraParams: dummyTriggerNodeParams
                    }) as string
                    await srv.wxTemplateMessageAction({
                        addresseeIds,
                        templateId,
                        url: urlValue,
                        values: valuesValues,
                        pageId
                    })
                    break
                }
                case 'stripe': {
                    const config = data[type]
                    const { record } = dummyTriggerNodeParams[dummyTriggerNodeId]
                    const redirectUrl = window.location.href
                    if (!record || !pageId) {
                        return
                    }
                    if (!user.userId) {
                        window.location.href = `/account/login?redirect=${encodeURIComponent(redirectUrl)}`
                        return
                    }
                    // Stripe支付
                    if (!record) {
                        return
                    }
                    const res = await srv.activeStripePay({
                        scene: {
                            type: PayTriggerType.INDEPENDENT,
                            independentConfig: {
                                blockId,
                                actionId
                            }
                        },
                        dsId: record.dsId,
                        recordId: record.id,
                        pageId
                        // failedFieldSettings: failedFieldSettings.map(field => ({
                        //     ...field,
                        //     value: generateVariableValue({
                        //         innerType: schema[field.fieldId]?.innerType || 'TEXT',
                        //         jsonContent: field.value,
                        //         extraParams: dummyTriggerNodeParams
                        //     }) as AnyObject
                        // }))
                    })
                    if (!res.outTradeNo) {
                        Toast.error(`获取Stripe支付订单异常`)
                        return
                    }

                    const { expiresAt, outTradeNo, clientSecret } = res
                    const newSearch: StripePaySearch = {
                        clientSecret,
                        outTradeNo,
                        expiresAt,
                        pageId,
                        autoRedirectAfterPayment: 1,
                        redirect: encodeURIComponent(redirectUrl)
                    }
                    const search = getSearchParams(newSearch)
                    navigate(`/pay/stripe?${search}`)
                    break
                }
                case 'refreshPage': {
                    actionRuntimeParams.actionEvents.refreshPage?.()
                    break
                }
                case 'aliyunMessage': {
                    const params = data[type]
                    const { id, mobileList, signatureId, templateId, values } = params
                    const mobileListValue = (resolveVariable(mobileList, dummyTriggerNodeParams) ?? '') as string
                    const valuesListValue = values.map(value => ({
                        variableName: value.variableName,
                        value: generateVariableValue({
                            innerType: 'TEXT',
                            jsonContent: value.value,
                            extraParams: dummyTriggerNodeParams
                        }) as string
                    }))

                    const payload = {
                        id,
                        pageId,
                        mobileList: mobileListValue,
                        signatureId,
                        templateId,
                        values: valuesListValue
                    }

                    await srv.activeAliyunMessageAction(payload)
                    break
                }
                case 'scrollTo': {
                    const params = data[type]
                    const { scrollToElementId } = params
                    scroll2FlowNode(scrollToElementId, scope)
                    break
                }
                case 'control': {
                    const params = data[type]
                    const { controlElementId, controlElementType, controlAction } = params
                    controlAction?.type &&
                        blockListenerSystem.emit(scope ? `${scope}@${controlElementId}` : controlElementId, controlElementType, {
                            event: controlAction.type,
                            params: 'params' in controlAction ? controlAction.params : undefined
                        })

                    break
                }
                case 'closeFloat': {
                    actionRuntimeParams.actionEvents.closeFloat()
                    break
                }
                default: {
                    break
                }
            }
        },
        [
            actionRuntimeParams,
            blockId,
            currentAppId,
            customViewRecord,
            dataSourceList,
            envId,
            getClickTriggerNodeParams,
            navigate,
            pageId,
            pageList,
            pageRecord,
            pageStackFormState,
            palettes,
            prevRecord,
            previewType,
            scope,
            sequenceSingleWorker,
            user.userId,
            userRecord,
            workflows
        ]
    )

    return {
        handleActionTrigger
    }
}
