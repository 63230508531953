import type {
    AiFieldStatus,
    DataSourceAbstract,
    DataSourceBase,
    DataSourceIdentity,
    RecordIdentity,
    RecordLikeProtocol,
    ViewIndependentData
} from '@lighthouse/core';
import { getMainTableRecordId } from '@lighthouse/shared';
import type { Draft } from 'immer';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { atomWithImmer } from 'jotai-immer';
import { find } from 'rambda';
import shallowEqual from 'shallowequal';

import { currentAppIdAtom, currentEnvIdAtom } from '../application/state';
import { applyDraftPayload } from '../utils/applyDraftPayload';
import type { ViewRecordPool } from './types';

export const dataSourceListIdsAtom = atomWithImmer<DataSourceBase[]>([])
export const dataSourcePoolAtom = atomWithImmer<DataSourceAbstract[]>([])
export const recordPoolAtom = atomWithImmer<RecordLikeProtocol[]>([])
export const currentDataSourceIdAtom = atom('')
export const aiFieldStatusListAtom = atomWithImmer<AiFieldStatus[]>([])

export const viewIndependentDataAtom = atomWithImmer<ViewRecordPool>({})

export const dataSourceBaseAtomFamily = atomFamily((dsId: string) => {
    return atom(
        get => get(dataSourceListIdsAtom).find(item => item.id === dsId),
        (_, set, payload: DataSourceBase | ((draft: Draft<DataSourceBase>) => void)) => {
            set(dataSourceListIdsAtom, draft => {
                const dataSourceBase = draft.find(item => item.id === dsId)
                if (!dataSourceBase) {
                    return
                }
                applyDraftPayload(dataSourceBase, payload)
            })
        }
    )
})

export const dataSourceAtomFamily = atomFamily((params: DataSourceIdentity) => {
    const { appId, envId, dsId } = params
    return atom(
        get => get(dataSourcePoolAtom).find(item => item.appId === appId && item.id === dsId && item.envId === envId),
        (_, set, payload: DataSourceAbstract | undefined | ((draft: Draft<DataSourceAbstract | undefined>) => void)) =>
            set(dataSourcePoolAtom, draft => {
                const dataSource = draft.find(item => item.id === dsId && item.appId === appId)
                applyDraftPayload(dataSource, payload)
            })
    )
}, shallowEqual)

export const recordAtomFamily = atomFamily((params: RecordIdentity) => {
    const { appId, envId, recordId, dsId } = params
    return atom(
        get => get(recordPoolAtom).find(item => item.appId === appId && item.envId === envId && item.dsId === dsId && item.id === recordId),
        (_, set, payload: RecordLikeProtocol | ((draft: Draft<RecordLikeProtocol>) => void)) =>
            // 解决连接表问题
            set(recordPoolAtom, draft => {
                const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                    if (cur.appId === appId && cur.dsId === dsId && getMainTableRecordId(cur.id) === getMainTableRecordId(recordId)) {
                        prev.push(index)
                    }
                    return prev
                }, [])
                if (recordsIndex.length === 0) {
                    return
                }
                recordsIndex.forEach(i => {
                    applyDraftPayload(draft[i], payload)
                })
            })
    )
}, shallowEqual)

export const recordListAtomFamily = atomFamily((params: { appId: string; dsId: string }) => {
    const { appId, dsId } = params
    return atom(get => get(recordPoolAtom).filter(item => item.appId === appId && item.dsId === dsId))
}, shallowEqual)


export const viewIndependentDataAtomFamily = atomFamily((viewId: string) => {
    return atom(
        get => {
            const appId = get(currentAppIdAtom)
            const envId = get(currentEnvIdAtom)
            const ViewRecordPool = get(viewIndependentDataAtom)
            const dsRecords = get(recordPoolAtom)
            const viewRecords = ViewRecordPool?.[viewId]

            if (!viewRecords) {
                return undefined
            }
            const { recordIds = [], ...rest } = viewRecords
            const records = recordIds.reduce<RecordLikeProtocol[]>((records, viewRecordId) => {
                const record = find(
                    item => appId === item.appId && viewRecordId === item.id && item.envId === envId,
                    dsRecords
                )
                if (record) {
                    const viewRecordData = record.content
                    records.push({
                        id: viewRecordId,
                        appId,
                        envId,
                        dsId: record.dsId,
                        content: viewRecordData
                    })
                }
                return records
            }, [])
            return { records, ...rest }
        },
        (_, set, payload: ViewIndependentData | ((draft: Draft<ViewIndependentData>) => void)) =>
            set(viewIndependentDataAtom, draft => {
                if (typeof payload === 'function') {
                    if (!draft[viewId]) {
                        draft[viewId] = {
                            recordIds: [],
                            currentPage: 1,
                            rowTotal: 0,
                            search: ''
                        }
                    }
                    applyDraftPayload(draft[viewId], payload)
                    return
                }
                // applyDraftPayload(draft[viewId], payload)
                draft[viewId] = payload
            })
    )
}, shallowEqual)

