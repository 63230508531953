import type { BlockAbstract, ViewBlockAbstract } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'

import type { FloatStateTree } from '../application/types'

export const isViewBlock = (block: BlockAbstract): block is ViewBlockAbstract => {
    return block.type === BlockType.view
}

// 更新Popover的状态，递归地查找并修改节点
export const findParentFloatTree = (floatTrees: FloatStateTree[], parentId: string): FloatStateTree | null => {
    let parentFloatTree: FloatStateTree | null = null
    function recursion(trees: FloatStateTree[]) {
        for (const tree of trees) {
            if (tree.blockId === parentId) {
                parentFloatTree = tree
                return
            }
            if (tree.children.length > 0) {
                recursion(tree.children)
            }
        }
    }
    recursion(floatTrees)
    return parentFloatTree
}

export const findFloatTree = (floatTrees: FloatStateTree[], filter: (tree: FloatStateTree) => boolean): FloatStateTree | null => {
    let currentTree: FloatStateTree | null = null
    function recursion(trees: FloatStateTree[]) {
        for (const tree of trees) {
            if (filter(tree)) {
                currentTree = tree
                return
            }
            if (tree.children.length > 0) {
                recursion(tree.children)
            }
        }
    }
    recursion(floatTrees)
    return currentTree
}
