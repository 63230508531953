import type { PopoverProps } from '@byecode/ui'
import { closeModal, IconFont, Message, openModal, Popover } from '@byecode/ui'
import { NavigationShowMode } from '@lighthouse/core'
import * as tools from '@lighthouse/tools'
import { hideNotification, NotificationsProvider } from '@mantine/notifications'
import { usePopupState } from 'material-ui-popup-state/hooks'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import type { MessageEvents, NotificationMessageData } from '../../types'
import type { ApplicationContainerPreviewMode } from '../ApplicationContainer/type'
import { NotificationDetail } from '../NotificationDetail'
import { NotificationPanel } from './NotificationPanel'

export interface NotificationProps {
    appId: string
    device?: 'mobile' | 'desktop'
    showMode?: NavigationShowMode
    previewMode?: ApplicationContainerPreviewMode
    messageEvents?: MessageEvents
    lastMessage: NotificationMessageData
}

const SCxNotificationWrapper = styled.div`
    /* width: 100%; */
    display: flex;
    flex: 0;
    align-items: center;
`

const SCxNotificationButton = styled.div`
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`

const Notification: React.FC<NotificationProps> = ({ appId, showMode, previewMode, device, messageEvents, lastMessage }) => {
    const isHorizontal = showMode === NavigationShowMode.horizontal
    const isMobile = device === 'mobile'
    const [open, setOpen] = useState(false)
    const popoverTriggerRef = useRef<HTMLDivElement>(null)
    const messageRef = useRef<NotificationMessageData>()

    const popoverProps: Pick<PopoverProps, 'position'> = useMemo(() => {
        if (isHorizontal) {
            return {
                position: 'bottom-end'
            }
        }

        return {
            position: 'bottom-start'
        }
    }, [isHorizontal])

    useEffect(() => {
        messageRef.current = lastMessage
    }, [lastMessage])

    const handleOpenDetail = useCallback(
        async (id?: string) => {
            if (!messageEvents?.onFetchMessages) {
                return
            }
            const { onFetchMessages, onFetchMessageDetail } = messageEvents
            const { messages } = await onFetchMessages()
            openModal({
                id: 'notification-detail',
                withCloseIcon: false,
                width: 1200,
                styles: {
                    desktop: {
                        header: { display: 'none' },
                        modal: {
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            height: '80%'
                        },
                        body: {
                            flex: 1,
                            position: 'relative',
                            overflow: 'hidden'
                        }
                    }
                },
                // target: PAGE_CONTAINER_HOST,
                children: (
                    <NotificationDetail
                        messageId={id}
                        appId={appId}
                        device={device}
                        data={messages}
                        onClose={() => closeModal('notification-detail')}
                        messageEvents={messageEvents}
                    />
                )
            })
        },
        [appId, device, messageEvents]
    )

    // const handleTrigger = useCallback(() => {
    //     popoverState.open(popoverTriggerRef.current)
    // }, [calcHeight, popoverState])

    useEffect(() => {
        if (!lastMessage || !messageRef.current) {
            return
        }

        messageRef.current = undefined

        switch (lastMessage.type) {
            case 'STATION_MESSAGE': {
                const nid = tools.nanoid(12)
                const { appName, title } = lastMessage
                Message.info({
                    title,
                    autoClose: false,
                    icon: false,
                    description: <div style={{ display: 'flex' }}>刚刚 · 来自 {appName}</div>,
                    onClick: ev => {
                        hideNotification(nid)
                        if (!(ev.target instanceof HTMLButtonElement) && !(ev.target instanceof SVGElement)) {
                            handleOpenDetail()
                        }
                    }
                })
                break
            }
            case 'TEXT_GEN_INVOKE': {
                break
            }
            default: {
                break
            }
        }
    }, [handleOpenDetail, lastMessage])

    return (
        <SCxNotificationWrapper>
            <Popover opened={open} onChange={setOpen} withinPortal width="auto" {...popoverProps}>
                <Popover.Target>
                    <SCxNotificationButton ref={popoverTriggerRef}>
                        <IconFont type="Bell" size={18} />
                    </SCxNotificationButton>
                </Popover.Target>
                <Popover.Dropdown>
                    <div style={{ width: isMobile ? 350 : 435 }}>
                        {open && (
                            <NotificationPanel appId={appId} device={device} onClose={() => setOpen(false)} messageEvents={messageEvents} />
                        )}
                    </div>
                </Popover.Dropdown>
            </Popover>
        </SCxNotificationWrapper>
    )
}

export const ProvidedNotification: React.FC<NotificationProps> = props => {
    return (
        <NotificationsProvider position="top-right" /* target="#host-page-container" */ style={{ position: 'absolute', maxWidth: 333 }}>
            <Notification {...props} />
        </NotificationsProvider>
    )
}
