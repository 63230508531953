import { findBlockById, getCascadeOption } from '@lighthouse/shared'
import { atom } from 'jotai'

import * as srv from '@/services'

import { addDataSourceAtom, addRecordAtom, setViewRecordAtom } from '../dataSource/action'
import { blocksAtom } from '../page/state'
import { isViewBlock } from '../utils/block'
import { filterBlockOptionsAtom } from './state'
import type {
    FetchChartBlockPayload,
    FetchViewRecordsAtomPayload,
    HandleFetchFilterCascadeOptionsPayload,
    HandleFetchFilterOptionsPayload,
    updateViewSearchAtomPayload
} from './types'

// export const fetchViewCountAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
//     const { viewId, isSyncComponent, search, params, pageId, abort } = payload
//     const rowTotal = await srv.getViewCount({ viewId, isSyncComponent, pageId, search, ...params, abort })
//     if (rowTotal === undefined) {
//         return
//     }
//     set(blocksAtom, draft => {
//         const currentBlocks = draft[pageId]
//         if (!currentBlocks) {
//             return
//         }

//         const draftBlock = findBlockById(viewId, currentBlocks)
//         if (!draftBlock) {
//             return
//         }
//         if (!isViewBlock(draftBlock)) {
//             return
//         }
//         draftBlock.config.pagination.rowTotal = rowTotal
//     })
// })


/** 获取视图数据 */
export const fetchViewRecordsAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
    const { appId, envId, scope, viewId, isSyncComponent, pagination, search, params, pageId, disableFetchCount, abort } = payload
    const viewData = await srv.getViewByBlock({
        appId,
        envId,
        viewId,
        isSyncComponent,
        pageId,
        pagination,
        params,
        search,
        abort
    })

    if (!viewData) {
        return
    }
    const { records } = viewData

    set(addRecordAtom, { records })
    const recordIds = records.map(record => record.id)

    if (!disableFetchCount) {
        const rowTotal = await srv.getViewCount(payload)
        set(setViewRecordAtom, {
            identifier: { viewId, scope }, data: draft => {
                draft.recordIds = recordIds
                draft.currentPage = pagination.currentPage ?? 1
                draft.search = search
                draft.rowTotal = rowTotal
            }
        })
        return viewData
    }

    set(setViewRecordAtom, {
        identifier: { viewId, scope }, data: draft => {
            draft.recordIds = recordIds
            draft.currentPage = pagination.currentPage ?? 1
            draft.search = search
        }
    })

    return viewData

})


/** 加载视图数据 */
export const loadViewRecordsAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
    const { appId, envId, scope, viewId, isSyncComponent, pagination, search, params, pageId, abort } = payload
    const viewData = await srv.getViewByBlock({
        appId,
        envId,
        pageId,
        viewId,
        isSyncComponent,
        pagination,
        params,
        search,
        abort
    })
    if (!viewData) {
        return []
    }
    const { records, config } = viewData

    set(addRecordAtom, { records })
    const recordIds = records.map(record => record.id)
    set(setViewRecordAtom, {
        identifier: { viewId, scope }, data: draft => {
            recordIds.forEach(id => {
                draft.recordIds.push(id)
            })
            draft.currentPage = pagination.currentPage ?? 1
            draft.search = search
        }
    })
    return records
})

export const updateViewSearchAtom = atom(null, (_, set, payload: updateViewSearchAtomPayload) => {
    const { pageId, viewId, search } = payload
    set(blocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = findBlockById(viewId, currentBlocks)
        if (!draftBlock) {
            return
        }

        if (!isViewBlock(draftBlock)) {
            return
        }

        draftBlock.config.search = search
    })
})

/** 获取图表数据 */
export const fetchChartBlockAtom = atom(null, async (_, set, payload: FetchChartBlockPayload) => {
    const data = await srv.getDsGraphView(payload)
    return data.chart
})

// 获取文本 成员筛选器 options
export const handleFetchFilterOptionsAtom = atom(null, async (_, set, payload: HandleFetchFilterOptionsPayload) => {
    const data = await srv.getRelativeOptions(payload)
    set(filterBlockOptionsAtom, draft => {
        draft[`${payload.blockId}-${payload.filterItemId}`] = data
    })
    return data
})

// 获取级联筛选器 options
export const handleFetchFilterCascadeOptionsAtom = atom(null, async (_, set, payload: HandleFetchFilterCascadeOptionsPayload) => {
    const {
        appId,
        envId,
        dsId,
        pageId,
        sortFieldPointer = '',
        fieldPointer,
        parentFieldPointer,
        blockId,
        filterItemId,
        pageSize,
        showFieldPointer
    } = payload
    const res = await srv.getDs({
        appId,
        envId,
        dsId,
        pageId,
        pagination: { currentPage: 1, pageSize },
        sorts: [{ id: 'cascadeSortId', order: 'ASC', fieldId: sortFieldPointer }]
    })
    const usedRecords = res?.records?.filter(record => record?.content?.[fieldPointer]?.value)
    const options =
        usedRecords
            ?.filter(record => !record?.content?.[parentFieldPointer]?.value)
            .map(record => getCascadeOption({ parentFieldPointer, fieldPointer, showFieldPointer, record, records: usedRecords }))
            .filter(item => item.value) ?? []
    set(filterBlockOptionsAtom, draft => {
        draft[`${blockId}-${filterItemId}`] = options
    })
    return options
})
