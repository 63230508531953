// import { BreadcrumbBlock } from '@lighthouse/block'
import { type BreadcrumbBlockAbstract, type PageAbstract } from '@lighthouse/core'
import { CUSTOM_ROUTE_PAGE_URL_REG, PAGE_URL_REG } from '@lighthouse/shared'
import { find, match } from 'rambda'
import React, { Suspense, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { useCurrentAppId, usePageList, usePreview } from '@/hooks/useApplication'
import { getCustomRoutePageUrl, parseUrlParams } from '@/hooks/useSubscriptRouteForPageStack'

const BreadcrumbBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.BreadcrumbBlock })))

interface BreadcrumbBlockControllerProps {
    blockData: BreadcrumbBlockAbstract
}

const BreadcrumbBlockController: React.FC<BreadcrumbBlockControllerProps> = ({ blockData }) => {
    const pageList = usePageList()
    const previewType = usePreview()
    const navigate = useNavigate()
    const location = useLocation()
    const appId = useCurrentAppId()

    const stackList = useMemo(
        () =>
            match(PAGE_URL_REG, location.pathname).map((stackUrl, index) => {
                const isCustomRoute = index === 0 && CUSTOM_ROUTE_PAGE_URL_REG.test(stackUrl)
                const realUrl = isCustomRoute ? getCustomRoutePageUrl(stackUrl.split('/')[0], pageList) : stackUrl
                return parseUrlParams(realUrl, appId)
            }),
        [appId, location.pathname, pageList]
    )

    const options = useMemo(() => {
        return stackList.map((item, index) => {
            const page = find(page => page.id === item.pageId, pageList)
            return { label: page?.name ?? '', value: item.stackId ?? item.pageId, jumpIndex: index }
        })
    }, [stackList, pageList])

    const handleLink = useCallback(
        (id: string, jumpIndex: number) => {
            const path = match(PAGE_URL_REG, window.location.pathname)
                .slice(0, jumpIndex + 1)
                .join('/')
            navigate({ pathname: `/${path}` })
        },
        [navigate]
    )

    return (
        <Suspense fallback={<div />}>
            <BreadcrumbBlock blockData={blockData} onLink={handleLink} options={options} previewType={previewType} />
        </Suspense>
    )
}

export default BreadcrumbBlockController
