import { handleAddIClickUser } from './handlers/addIClickUser'
import { handleAliyunMessage } from './handlers/aliyunMessage'
import { handleCall } from './handlers/call'
import { handleCloseFloat } from './handlers/closeFloat'
import { handleCondition } from './handlers/condition'
import { handleCopy } from './handlers/copy'
import { handleCreateRecord } from './handlers/createRecord'
import { handleDeleteRecord } from './handlers/deleteRecord'
import { handleDingTalkRobot } from './handlers/dingTalkRobot'
import { handleDownloadFile } from './handlers/downloadFile'
import { handleFindSingleRecord } from './handlers/findSingleRecord'
import { handleGoBack } from './handlers/goBack'
import { handleSubProcess } from './handlers/handleSubProcess'
import { handleNotification } from './handlers/notification'
import { handleOpenFormPage } from './handlers/openFormPage'
import { handleOpenLink } from './handlers/openLink'
import { handleOpenPage } from './handlers/openPage'
import { handleOpenRecordEditPage } from './handlers/openRecordEditPage'
import { handleOpenRecordPage } from './handlers/openRecordPage'
import { handleRefreshPage } from './handlers/refreshPage'
import { handleSendEmail } from './handlers/sendEmail'
import { handleStationMessage } from './handlers/stationMessage'
import { handleStripePay } from './handlers/stripe'
import { handleUpdateRecord } from './handlers/updateRecord'
import { handleWechatPay } from './handlers/wechatpay'
import { handleWechatTemplateMsg } from './handlers/wechatTemplateMsg'
import { handleWxRobot } from './handlers/wxRobot'

export const actionHandlers = {
    COPY: handleCopy,
    CALL: handleCall,
    GO_BACK: handleGoBack,
    NOTIFICATION: handleNotification,
    OPEN_PAGE: handleOpenPage,
    OPEN_LINK: handleOpenLink,
    OPEN_RECORD_PAGE: handleOpenRecordPage,
    OPEN_FORM_PAGE: handleOpenFormPage,
    OPEN_RECORD_EDIT_PAGE: handleOpenRecordEditPage,
    CREATE_SINGLE_RECORD_ACTION: handleCreateRecord,
    UPDATE_RECORD_ACTION: handleUpdateRecord,
    FIND_SINGLE_RECORD_ACTION: handleFindSingleRecord,
    DELETE_RECORD: handleDeleteRecord,
    WX_ROBOT_ACTION: handleWxRobot,
    STATION_MESSAGE_ACTION: handleStationMessage,
    DINGTALK_ROBOT_ACTION: handleDingTalkRobot,
    SEND_EMAIL_ACTION: handleSendEmail,
    I_CLICK_CONTACT_ACTION: handleAddIClickUser,
    DOWNLOAD_FILE: handleDownloadFile,
    CONDITION: handleCondition,
    WECHAT_PAY: handleWechatPay,
    WECHAT_TEMPLATE_MSG_ACTION: handleWechatTemplateMsg,
    STRIPE: handleStripePay,
    REFRESH_PAGE: handleRefreshPage,
    ALIYUN_MESSAGE_ACTION: handleAliyunMessage,
    HANDLE_SUB_PROCESS: handleSubProcess,
    CLOSE_FLOAT: handleCloseFloat
}
