import type { ActionFlowNode, OpenLinkActionConfig } from '@lighthouse/shared'
import { generateVariableValue, openNewBrowserPage } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleOpenLink = async (node: ActionFlowNode<OpenLinkActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { link, target } = config as OpenLinkActionConfig

    if (!link) {
        return excParams
    }

    const realLink = generateVariableValue({  innerType: 'TEXT', jsonContent: link, extraParams: excParams })

    // window.open(Array.isArray(realLink) ? '' : realLink, '_blank')
    openNewBrowserPage(Array.isArray(realLink) ? '' : String(realLink), target)

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        link: realLink
    })
}
