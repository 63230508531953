import { useCustomViewBlockContext } from '@lighthouse/block'
import type { FieldInputType, VariableADTvalue } from '@lighthouse/core'
import { useApplicationContext, useFormModuleContext } from '@lighthouse/shared'
import { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'

import { useCurrentAppId, useCurrentEnvId } from './useApplication'
import { useDataSource, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'
import { useVariableValueRender } from './useVariableValueRender'

export interface IUsePageCurrentDsRecord {}

export interface IUsePageCurrentDsAndRecord {
    fieldPointer?: string
    required?: boolean
    inputType: FieldInputType
}

export const useFieldInputRenderLabel = () => {
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const { record: viewRecord } = useCustomViewBlockContext()

    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()
    const { renderLabel } = useVariableValueRender(pageId, prev?.recordId, curr.recordId)

    return useCallback(
        (v?: VariableADTvalue) => {
            return renderLabel(v, { personOptions, roleOptions, departmentOptions, viewRecord })
        },
        [departmentOptions, personOptions, renderLabel, roleOptions, viewRecord]
    )
}

export const usePageCurrentDsRecord = () => {
    const { pageId } = useCurrentPageContext()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const appId = useCurrentAppId()

    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const currentPageDs = useDataSource(appId, envId, curr.page?.dsId || '')
    const pageDepsDs = curr.datasource ?? prev.datasource ?? currentPageDs
    const pageDepsRecordId = curr.recordId ?? prev.recordId
    const currentPageRecord = useRecord(appId, envId, pageDepsDs?.id || '', pageDepsRecordId ?? '')

    return {
        dataSource: pageDepsDs,
        record: currentPageRecord
    }
}

export const useParentRecord = () => {
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const { pageId } = useCurrentPageContext()

    const { dataSource: currentPageDataSource, record: currentPageRecord } = usePageCurrentDsRecord()

    const { record: viewRecord, pointer: viewPointer } = useCustomViewBlockContext()

    const { record: formRecord } = useFormModuleContext()

    const record = formRecord ?? viewRecord ?? currentPageRecord

    const viewDataSource = useDataSource(appId, envId, viewPointer)

    const dataSource = viewDataSource ?? currentPageDataSource

    const [initRecord, setInitRecord] = useState(currentPageRecord)

    useUpdateEffect(() => {
        setInitRecord(v => {
            if (!v) {
                return record
            }
            return v
        })
    }, [record])

    return {
        dataSource,
        record,
        initRecord
    }
}
