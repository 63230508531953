// import { AdvancedTableBlock, CalendarBlock, GalleryBlock, KanbanBoardBlock, ListBlock, TableBlock } from '@lighthouse/block'
import { Loading, Modal, Toast } from '@byecode/ui'
import { AnimationDecorators, useContainerBlockContext } from '@lighthouse/block'
import type {
    ButtonAction,
    FieldADTValue,
    FilterCommonCondition,
    GroupConfigure,
    GroupRecordCount,
    GroupTab,
    RecordLikeProtocol,
    Sorter,
    TableColumns,
    TableColumnWidth,
    VariableADTvalue,
    VariableFieldADTValue,
    ViewBlockAbstract
} from '@lighthouse/core'
import { AppUserStatus, SelectedMode, VariableType } from '@lighthouse/core'
import type { FlowLayoutNode, ViewAppendParams } from '@lighthouse/shared'
import {
    ALL_GROUP,
    CenteredWrapper,
    DEFAULT_FILTER_VALUE_VARIABLE,
    EMPTY_COLUMN_GROUP,
    getGroupAllVisibleOptions,
    getGroupVisibleWithLabelOptions,
    getInitQuickFilter,
    getMainTableRecordId,
    getPaddingStyle,
    getRefetchViewPageSize,
    getUrlNameByOpenType,
    getVariableFieldId,
    getWithScopeId,
    isIdsValue,
    mergeGroupVisibleOptions,
    pageStackPubSub,
    scroll2EndLeftOrRight,
    scroll2ViewItem,
    useApplicationContext,
    useAtomAction,
    useAtomAsyncAction,
    useAtomData,
    useHandleAbortPrevious,
    useLanguageContext,
    useRegisterBlockListener,
    ViewBlockTool
} from '@lighthouse/shared'
import { nanoid, useBreakpoint } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import { find, intersection, isEmpty } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { fetchViewRecordsAtom, loadViewRecordsAtom, updateViewSearchAtom } from '@/atoms/blockRecordsDict/action'
import { addAiFieldStatusAtom, deleteRecordAtom, deleteViewRecordAtom, updateCellAtom, updateRecordAtom } from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom } from '@/atoms/dataSource/state'
import { pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import {
    groupCacheAtomFamily,
    kanbanColumnsSortCacheAtomFamily,
    quickFilterCacheAtomFamily,
    sortsCacheAtomFamily,
    tableColumnCacheAtomFamily,
    tablePropsCacheAtomFamily
} from '@/atoms/storage/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppId, useCurrentEnvId, usePreview } from '@/hooks/useApplication'
import { useViewIndependentData } from '@/hooks/useBlock'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'

import { useViewBlockConfig } from './hook'
import type { FetchViewDataPayload, GetGroupRecordCountPayload } from './types'
import { ViewBlockRender } from './ViewBlockRender'

const SCxViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* margin: var(--block-padding); */
`

interface ViewBlockControllerProps {
    blockData: ViewBlockAbstract
    node: FlowLayoutNode
    onBlockChange?: (values: ViewBlockAbstract, origin: ViewBlockAbstract) => Promise<void> | void
}

type RecordAddPayload = Record<string, string | number> | React.SyntheticEvent

function isSyntheticEvent(obj: RecordAddPayload): obj is React.SyntheticEvent {
    return 'nativeEvent' in obj
}

const ViewBlockController: React.FC<ViewBlockControllerProps> = ({ blockData, node, onBlockChange }) => {
    const firstRef = useRef(true)
    const viewIdRef = useRef('')
    const { id, config } = blockData
    const {
        pointer,
        viewType,
        viewingConfig,
        canCreateRecord,
        creatingConfig,
        canSearch,
        triggerWorkflow,
        repeat,
        compareFields,
        importMode,
        quickFilter,
        pagination: { pageSize },
        groupConfigure,
        canGroup,
        canSort,
        editingConfig,
        canPaginate,
        breakPoint: baseBreakpoint
    } = config
    const noPagination = viewType === 'kanban' || viewType === 'calendar' || !canPaginate
    const { groupByFieldId = '', groupConfig, canHiddenTabValue } = groupConfigure || {}
    // 如果 realLayoutId 存在，说明需要作为 realLayoutId 所在 layout 进行处理
    // const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const appId = useCurrentAppId()
    const navigate = useNavigate()
    const { pageId } = useCurrentPageContext()
    const { scope } = useContainerBlockContext()

    // const pageList = useDefaultPageList()
    // const workflows = useAtomData(workflowListAtom)
    const stackId = useCurrentStackIdContext()
    const withScopeId = getWithScopeId(id, scope)

    const previewType = usePreview()
    // const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    // const [selectedMode, setSelectedMode] = useState<SelectedMode | undefined>(undefined)
    const { page: recordPageId = '' } = viewingConfig ?? {}
    const { page: formPageId = '' } = creatingConfig ?? {}
    const { page: recordEditPageId = '' } = editingConfig ?? {}
    const { handleActionTrigger } = useActionTrigger(id)

    const { personOptions } = useApplicationContext()
    const dataSource = useDataSource(appId, envId, pointer)
    const dataSourceList = useDataSourceList(appId)
    const viewIndependentData = useViewIndependentData(withScopeId)
    const { records, currentPage = 1, rowTotal, search = '' } = viewIndependentData || {}

    const viewIndependentDataOmitRecord = useMemo(
        () => ({
            currentPage,
            rowTotal,
            search
        }),
        [currentPage, rowTotal, search]
    )

    const searchVal = useMemo(() => (canSearch ? search : ''), [canSearch, search])

    const { handleRenderTitle } = useRichTextToTitle()
    const { convertTextByLanguage } = useLanguageContext()

    const selectState = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(
            s => {
                if (!s) {
                    return
                }
                const isSelf = id === s.blockRuntimeState.view?.viewId && scope === s.blockRuntimeState.view?.scope
                if (!isSelf) {
                    return
                }
                return s.blockRuntimeState.view
                // return s.state.blockRuntimeState.tabs?.[blockData.id].currentTab
            },
            [id, scope]
        )
    )

    const quickFiltersCache = useAtomValue(quickFilterCacheAtomFamily({ appId, envId, id: withScopeId }))

    const tablePropsCache = useAtomValue(tablePropsCacheAtomFamily({ appId, envId, id: withScopeId }))

    const sortCache = useAtomValue(sortsCacheAtomFamily({ appId, envId, id: withScopeId }))

    const groupCache = useAtomValue(groupCacheAtomFamily({ appId, envId, id: withScopeId }))

    const tableColumnCache = useAtomValue(tableColumnCacheAtomFamily({ appId, envId, id: withScopeId }))

    const kanbanColumnsSortCache = useAtomValue(kanbanColumnsSortCacheAtomFamily({ appId, envId, id: withScopeId }))

    const { selectedIds, selectedMode } = useMemo(() => {
        if (!selectState) {
            return { selectedIds: [] as string[], selectedMode: undefined }
        }
        return selectState
    }, [selectState])

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const groupByField = useMemo(() => {
        if (!dataSource) {
            return
        }
        const { schema } = dataSource
        const field = schema[groupByFieldId]
        if (!field) {
            return
        }
        return field
    }, [dataSource, groupByFieldId])

    // 这里的依赖是视图所在页面的页面栈依赖

    const { run: updateViewSearch } = useAtomAction(updateViewSearchAtom)
    const { run: fetchViewRecords, loading } = useAtomAsyncAction(fetchViewRecordsAtom)
    // const { run: createRecord } = useAtomAsyncAction(createRecordAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const { run: updateRecord } = useAtomAsyncAction(updateRecordAtom)
    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: deleteViewRecord } = useAtomAction(deleteViewRecordAtom)
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const { mutation } = useHandleAbortPrevious(fetchViewRecords)
    const { mutation: getGroupRecordCountMutation } = useHandleAbortPrevious(srv.getGroupRecordCount)
    const { run: setQuickFilterCache } = useAtomAction(quickFilterCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setSortsCache } = useAtomAction(sortsCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setTablePropsCache } = useAtomAction(tablePropsCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setGroupCache } = useAtomAction(groupCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setTableColumnCache } = useAtomAction(tableColumnCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setKanbanColumnsSortCache } = useAtomAction(kanbanColumnsSortCacheAtomFamily({ appId, envId, id: withScopeId }))

    const { ref, width: blockWidth, breakPoint } = useBreakpoint([loading])
    const getInitGroupConfigData = useCallback(() => {
        if (groupByField && groupConfig) {
            return mergeGroupVisibleOptions(
                getGroupVisibleWithLabelOptions(groupByField, groupConfig, {
                    personOptions
                }),
                getGroupAllVisibleOptions(groupByField, { personOptions }, true)
            )
        }
        return []
    }, [groupByField, groupConfig, personOptions])

    const initGroup = useMemo(() => {
        if (canGroup) {
            return (
                groupCache || {
                    groupByFieldId,
                    canHiddenTabValue,
                    groupConfig: getInitGroupConfigData()
                }
            )
        }
        return {
            groupByFieldId: groupByField?.id || '',
            canHiddenTabValue,
            groupConfig: getInitGroupConfigData()
        }
    }, [canGroup, groupByField?.id, canHiddenTabValue, getInitGroupConfigData, groupCache, groupByFieldId])

    const initTabList = useMemo(() => {
        if (!initGroup?.groupConfig) {
            return []
        }
        return initGroup.groupConfig
            .filter(v => v.visible)
            .map(item => ({
                value: item.value,
                label: item.label,
                count: undefined
            }))
    }, [initGroup.groupConfig])

    const [tabValue, setTabValue] = useState('')
    const [tabCountList, setTabCountList] = useImmer<GroupRecordCount[] | undefined>(undefined)
    const [tabList, setTabList] = useState<GroupTab[]>([])

    const quickFiltersRule = useMemo(() => {
        const rules = [...quickFiltersCache]
        if (quickFilter.mode === 'disable') {
            return []
        }
        return (
            quickFilter.fieldIds?.reduce<FilterCommonCondition[]>((acc, fieldId) => {
                const rule = find(rule => getVariableFieldId(rule.idVariable) === fieldId, rules)
                if (!dataSource) {
                    return acc
                }
                const field = dataSource.schema[fieldId]
                if (!field) {
                    return acc
                }
                if (rule && field.type === 'select') {
                    const { options } = field.select
                    const labels = options.map(item => item.label)
                    const newParamList: VariableADTvalue[] = rule?.paramList?.map(param => {
                        if (param.type === VariableType.VALUE) {
                            const value = param.valueVariable?.value
                            if (isIdsValue(value)) {
                                const newValue = intersection(labels, value)
                                return {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        value: newValue,
                                        type: 'select'
                                    }
                                }
                            }
                        }
                        return param
                    }) || [DEFAULT_FILTER_VALUE_VARIABLE]
                    const r: FilterCommonCondition = { ...rule, paramList: newParamList }
                    const newRule = getInitQuickFilter(field, quickFilter.mode, r)
                    acc.push(newRule)
                    return acc
                }
                if (rule && field.type === 'person') {
                    const userIds = personOptions.reduce<string[]>(
                        (prev, cur) => {
                            if (cur.status?.[0] === AppUserStatus.DEPART) {
                                return prev
                            }
                            prev.push(cur.userId)
                            return prev
                        },
                        ['{currentUserId}']
                    )
                    const newParamList: VariableADTvalue[] = rule?.paramList?.map(param => {
                        if (param.type === VariableType.VALUE) {
                            const value = param.valueVariable?.value
                            if (isIdsValue(value)) {
                                const newValue = intersection(userIds, value)
                                return {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        value: newValue,
                                        type: 'person'
                                    }
                                }
                            }
                        }
                        return param
                    }) || [DEFAULT_FILTER_VALUE_VARIABLE]
                    const r: FilterCommonCondition = { ...rule, paramList: newParamList }
                    const newRule = getInitQuickFilter(field, quickFilter.mode, r)
                    acc.push(newRule)
                    return acc
                }
                const newRule = getInitQuickFilter(field, quickFilter.mode, rule)
                acc.push(newRule)
                return acc
            }, []) || []
        )
    }, [dataSource, personOptions, quickFilter.fieldIds, quickFilter.mode, quickFiltersCache])

    const getFilterByTab = useCallback<(tab: string) => FilterCommonCondition[]>(
        (tab: string) => {
            if (!tab || tab === ALL_GROUP || !initGroup) {
                return []
            }
            if (tab === EMPTY_COLUMN_GROUP) {
                return [
                    {
                        idVariable: { type: VariableType.FIELD_ID, fieldIdVariable: { fieldId: initGroup.groupByFieldId } },
                        operator: 'isEmpty',
                        paramList: [{ type: VariableType.VALUE }]
                    }
                ]
            }
            const field = dataSource?.schema?.[initGroup.groupByFieldId]
            if (!field) {
                return []
            }
            return [
                {
                    idVariable: { type: VariableType.FIELD_ID, fieldIdVariable: { fieldId: initGroup.groupByFieldId } },
                    operator: 'contains',
                    paramList: [{ type: VariableType.VALUE, valueVariable: { type: field.type, value: [tab] } as VariableFieldADTValue }]
                }
            ]
        },
        [dataSource?.schema, initGroup]
    )

    const fetchViewData = useCallback(
        async (params: FetchViewDataPayload) => {
            const { filter, quickFilters, linkFilter, sorts, tab, search, pageNum } = params
            const filters: FilterCommonCondition[] = [...quickFilters, ...getFilterByTab(tab)]
            const size = getRefetchViewPageSize(viewType, pageSize)
            if (pointer) {
                await mutation({
                    appId,
                    envId,
                    pageId,
                    viewId: id,
                    scope,
                    isSyncComponent: !!scope,
                    pagination: { currentPage: pageNum, pageSize: size },
                    params: { filter, quickFilters: filters, linkFilter, sorts },
                    search,
                    disableFetchCount: noPagination
                })
            }
        },
        [getFilterByTab, viewType, pageSize, pointer, mutation, appId, envId, pageId, id, scope, noPagination]
    )

    const getGroupRecordCount = useCallback(
        async (params: GetGroupRecordCountPayload) => {
            const { filter, groupConfigure, quickFilters, linkFilter, search } = params
            if (groupConfigure?.groupByFieldId) {
                const data = await getGroupRecordCountMutation({
                    appId,
                    viewId: id,
                    isSyncComponent: !!scope,
                    pageId,
                    fieldId: groupConfigure?.groupByFieldId || '',
                    quickFilters,
                    linkFilter,
                    filter,
                    search
                })
                setTabCountList(data)
                return
            }
            if (!isEmpty(tabCountList)) {
                setTabCountList([])
            }
            fetchViewData({ filter, quickFilters, linkFilter, sorts: sortCache, tab: tabValue, search, pageNum: 1 })
        },
        [appId, fetchViewData, getGroupRecordCountMutation, id, pageId, scope, setTabCountList, sortCache, tabCountList, tabValue]
    )

    const { reloadData, resolvedFilter, resolvedLinkFilter } = useViewBlockConfig({
        appId,
        envId,
        pageId,
        blockData,
        groupConfigure: initGroup,
        quickFiltersRule,
        searchVal,
        sortCache,
        tabValue,
        tabList,
        initTabList,
        tabCountList,
        personOptions,
        dataSourceList,
        setTabValue,
        setTabList,
        fetchViewData,
        getGroupRecordCount
    })

    const handleQuickFilter = useCallback(
        (data: FilterCommonCondition[]) => {
            setQuickFilterCache(data)
            reloadData({ filter: resolvedFilter, groupConfigure: initGroup, quickFilters: data, search: searchVal, pageNum: 1 })
        },
        [initGroup, reloadData, resolvedFilter, searchVal, setQuickFilterCache]
    )

    const quickFilterData = useMemo(() => {
        return {
            mode: quickFilter?.mode || 'normal',
            rules: quickFiltersRule
        }
    }, [quickFilter?.mode, quickFiltersRule])

    const handleAction = useCallback(
        async (action: ButtonAction, record?: RecordLikeProtocol) => {
            // eslint-disable-next-line no-return-await
            return await handleActionTrigger(action, { viewRecord: record })
        },
        [handleActionTrigger]
    )

    const handleTabChange = useCallback(
        async (val: string) => {
            setTabValue(val)
            await fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: sortCache,
                tab: val,
                search: searchVal,
                pageNum: 1
            })
            await getGroupRecordCount({
                filter: resolvedFilter,
                groupConfigure: initGroup,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                search: searchVal
            })
        },
        [fetchViewData, getGroupRecordCount, initGroup, quickFiltersRule, resolvedFilter, resolvedLinkFilter, searchVal, sortCache]
    )

    const handleSearch = useCallback(
        (val: string) => {
            // setSearch(val)
            updateViewSearch({ pageId, viewId: id, search: val })
            reloadData({
                filter: resolvedFilter,
                groupConfigure: initGroup,
                quickFilters: quickFiltersRule,
                search: val,
                pageNum: 1
            })
            // fetchViewData(quickFiltersRule, sortCache, tabValue, 1, val)
        },
        [id, initGroup, pageId, quickFiltersRule, reloadData, resolvedFilter, updateViewSearch]
    )

    const handleChangePageNum = useCallback(
        (pageNum: number) => {
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: sortCache,
                tab: tabValue,
                search: searchVal,
                pageNum
            })
        },
        [fetchViewData, quickFiltersRule, resolvedFilter, resolvedLinkFilter, searchVal, sortCache, tabValue]
    )

    const { run: loadMoreRecords } = useAtomAsyncAction(loadViewRecordsAtom)
    const handleLoadMoreRecords = useCallback(
        (pageNum: number) => {
            const filters: FilterCommonCondition[] = [...quickFiltersRule, ...getFilterByTab(tabValue)]
            return loadMoreRecords({
                appId,
                envId,
                pageId,
                viewId: id,
                isSyncComponent: !!scope,
                pagination: { currentPage: pageNum, pageSize },
                params: { filter: resolvedFilter, quickFilters: filters, sorts: sortCache, linkFilter: resolvedLinkFilter },
                search
            })
        },
        [
            appId,
            envId,
            getFilterByTab,
            id,
            loadMoreRecords,
            pageId,
            pageSize,
            quickFiltersRule,
            resolvedFilter,
            resolvedLinkFilter,
            scope,
            search,
            sortCache,
            tabValue
        ]
    )

    const handleValueChange = useCallback(
        (recordId: string, fieldValue: FieldADTValue) => {
            return updateCell({ appId, dsId: pointer, recordId, pageId, value: fieldValue, fieldId: fieldValue.id })
        },
        [appId, pageId, pointer, updateCell]
    )

    const handleCellUpdate = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            srv.updateCell({
                appId,
                envId,
                dsId: pointer,
                id: recordId,
                fieldId: fieldValue.id,
                pageId,
                content: fieldValue
            }),
        [appId, envId, pageId, pointer]
    )
    const handleRecordDelete = useCallback(
        async (dsId: string, ids: string[], selectedMode?: SelectedMode) => {
            const isConfirm = await Modal.confirm({
                title: convertTextByLanguage('sureDelete'),
                content: convertTextByLanguage('deleteRecordWaring', {
                    count: selectState?.selectedMode === 'ALL' ? rowTotal : ids.length
                }),
                cancelText: convertTextByLanguage('cancel'),
                okText: convertTextByLanguage('delete'),
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete =
                    // eslint-disable-next-line no-negated-condition
                    selectedMode !== 'ALL'
                        ? await deleteRecord({ appId, pageId, dsId, recordIds: ids, mode: selectedMode })
                        : await deleteViewRecord({
                              appId,
                              pageId,
                              dsId,
                              viewId: id,
                              isSyncComponent: !!scope,
                              recordIds: ids,
                              mode: selectedMode,
                              sorts: sortCache ?? [],
                              quickFilters: quickFilterData?.rules ?? []
                          })
                if (isDelete) {
                    reloadData({
                        filter: resolvedFilter,
                        groupConfigure: initGroup,
                        quickFilters: quickFiltersRule,
                        search: searchVal,
                        pageNum: 1
                    })
                }
                return isDelete
            }
            return false
        },
        [
            appId,
            convertTextByLanguage,
            deleteRecord,
            deleteViewRecord,
            id,
            initGroup,
            pageId,
            quickFilterData?.rules,
            quickFiltersRule,
            reloadData,
            resolvedFilter,
            rowTotal,
            scope,
            searchVal,
            selectState?.selectedMode,
            sortCache
        ]
    )

    const handleViewRefresh = useCallback(() => {
        if (
            (viewType === 'custom' && baseBreakpoint.viewLayout?.direction === 'horizontal') ||
            (viewType === 'gallery' && baseBreakpoint.viewLayout?.direction === 'horizontal')
        ) {
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: sortCache,
                tab: tabValue,
                search: searchVal,
                pageNum: 1
            })
            return
        }
        fetchViewData({
            filter: resolvedFilter,
            quickFilters: quickFiltersRule,
            linkFilter: resolvedLinkFilter,
            sorts: sortCache,
            tab: tabValue,
            search: searchVal,
            pageNum: 1
        })
    }, [
        baseBreakpoint.viewLayout?.direction,
        fetchViewData,
        quickFiltersRule,
        resolvedFilter,
        resolvedLinkFilter,
        searchVal,
        sortCache,
        tabValue,
        viewType
    ])

    useRegisterBlockListener(withScopeId, 'view', {
        refresh: handleViewRefresh,
        horizontalScrollTo: payload => {
            const to = payload?.horizontalScrollTo
            const horizontalScrollDirection = payload?.horizontalScrollDirection
            const viewContainer = ref.current
            const items = viewContainer?.querySelectorAll('[data-view-item]')
            const viewItemsWrapper = items?.[0]?.parentElement
            if (!to || !items || !viewItemsWrapper) {
                return
            }
            const viewContentContainer = viewItemsWrapper?.parentElement
            const currentScrollLeft = viewItemsWrapper?.parentElement?.scrollLeft ?? 0
            const itemOffsetWidth = items[1].getBoundingClientRect().left - items[0].getBoundingClientRect().left
            const currentIndex = Math.round(currentScrollLeft / itemOffsetWidth)

            let newIndex

            if (!Number.isNaN(Number(to))) {
                newIndex = horizontalScrollDirection === 'left' ? currentIndex - Number(to) : currentIndex + Number(to)
            }

            if (to === 'end') {
                viewContentContainer &&
                    (horizontalScrollDirection === 'left'
                        ? scroll2EndLeftOrRight(viewContentContainer, { left: 0, behavior: 'smooth' })
                        : scroll2EndLeftOrRight(viewContentContainer, { left: viewItemsWrapper.clientWidth, behavior: 'smooth' }))
            }

            if (newIndex === undefined) {
                return
            }

            scroll2ViewItem({ id, scope }, newIndex, { block: 'nearest', inline: 'nearest' })
        }
    })

    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${pointer}-ADD`, (record?: RecordLikeProtocol) => {
            handleViewRefresh()
        })
        return () => unSubscribe(subscribeId)
    }, [handleViewRefresh, pointer])

    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${pointer}-UPDATE`, (record?: RecordLikeProtocol) => {
            reloadData({ filter: resolvedFilter, groupConfigure: initGroup, quickFilters: quickFiltersRule, search: searchVal, pageNum: 1 })
        })
        return () => unSubscribe(subscribeId)
    }, [reloadData, pointer, quickFiltersRule, searchVal, initGroup, resolvedFilter])

    const handleRecordAdd = useCallback(
        (initialValues?: RecordAddPayload) => {
            const initialValuesData = initialValues && isSyntheticEvent(initialValues) ? undefined : initialValues
            if (!canCreateRecord || !formPageId) {
                return
            }

            const base = `./${getUrlNameByOpenType(creatingConfig?.openType)}/${formPageId}/a/${appId}/d/${pointer}/v/${id}`
            const mergePath = base + (initialValuesData ? `/i/${JSON.stringify(initialValuesData)}` : '')
            navigate(mergePath, { relative: 'path' })
        },
        [canCreateRecord, formPageId, creatingConfig?.openType, appId, pointer, id, navigate]
    )

    const handleRecordClick = useCallback(
        (recordId: string) => {
            if (!recordPageId) {
                return
            }
            const rId = getMainTableRecordId(recordId)
            const url = scope
                ? `./${getUrlNameByOpenType(viewingConfig?.openType)}/${recordPageId}/a/${appId}/d/${pointer}/s/${scope}/v/${id}/r/${rId}`
                : `./${getUrlNameByOpenType(viewingConfig?.openType)}/${recordPageId}/a/${appId}/d/${pointer}/v/${id}/r/${rId}`
            navigate(url, {
                relative: 'path'
            })
        },
        [appId, id, navigate, pointer, recordPageId, scope, viewingConfig?.openType]
    )

    const handleRecordEditClick = useCallback(
        (recordId: string) => {
            if (!recordEditPageId) {
                return
            }
            const rId = getMainTableRecordId(recordId)
            const url = scope
                ? `./${getUrlNameByOpenType(
                      editingConfig?.openType
                  )}/${recordEditPageId}/a/${appId}/d/${pointer}/s/${scope}/v/${id}/r/${rId}`
                : `./${getUrlNameByOpenType(editingConfig?.openType)}/${recordEditPageId}/a/${appId}/d/${pointer}/v/${id}/r/${rId}`
            navigate(url, {
                relative: 'path'
            })
        },
        [recordEditPageId, navigate, editingConfig?.openType, appId, pointer, scope, id]
    )

    const handleRecordUpdate = useCallback(
        (recordId: string, content: RecordLikeProtocol['content']) => {
            return updateRecord({ appId, dsId: pointer, id: recordId, content, pageId })
        },
        [appId, pointer, pageId, updateRecord]
    )

    const handleSorterChange = useCallback(
        (data: Sorter[] | undefined) => {
            setSortsCache(data)
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: data || [],
                tab: tabValue,
                search: searchVal,
                pageNum: 1
            })
        },
        [fetchViewData, quickFiltersRule, resolvedFilter, resolvedLinkFilter, searchVal, setSortsCache, tabValue]
    )

    const handleDisplayChange = useCallback(
        (data?: TableColumns) => {
            setTablePropsCache(data)
        },
        [setTablePropsCache]
    )

    const handleGroupChange = useCallback(
        (data: GroupConfigure) => {
            setGroupCache(data)
        },
        [setGroupCache]
    )

    const handleAppend = useCallback(
        async (params: ViewAppendParams) => {
            const isAppend = await srv.appendDataToDataSource({
                ...params,
                sheetDto: {
                    ...params.sheetDto,
                    triggerWorkflow,
                    repeat,
                    compareFields,
                    importMode
                },
                pageId
            })
            isAppend && Toast.success(convertTextByLanguage('importSuccess'))
            reloadData({
                filter: resolvedFilter,
                groupConfigure: initGroup,
                quickFilters: quickFiltersRule,
                search: searchVal,
                pageNum: currentPage
            })
            return isAppend
            // await fetchViewData(quickFiltersRule, sortCache, tabValue)
        },
        [
            compareFields,
            convertTextByLanguage,
            currentPage,
            importMode,
            initGroup,
            pageId,
            quickFiltersRule,
            reloadData,
            repeat,
            resolvedFilter,
            searchVal,
            triggerWorkflow
        ]
    )

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const statusId = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: pointer, recordId, fieldId, pageId })
            if (isSuccess) {
                addAiFieldStatus({
                    id: statusId,
                    dataSourceId: pointer,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, pointer, pageId]
    )

    const handleSelectModeChange = useCallback(
        (val?: SelectedMode) => {
            const selectedIds = val ? records?.map(item => item.id) || [] : []
            setPageStack(draft => {
                const stack = draft.find(equalPageStack(pageId, stackId))
                if (!stack) {
                    return
                }

                stack.blockRuntimeState.view = {
                    viewId: id,
                    scope,
                    selectedMode: val,
                    selectedIds
                }
            })
        },
        [id, pageId, records, scope, setPageStack, stackId]
    )

    const handleTableColumnWidthChange = useCallback(
        (val: TableColumnWidth) => {
            const column = {
                ...tableColumnCache,
                ...val
            }
            setTableColumnCache(column)
        },
        [setTableColumnCache, tableColumnCache]
    )

    const handleSelectedRecords = useCallback(
        (ids: string[]) => {
            setPageStack(draft => {
                const stack = draft.find(equalPageStack(pageId, stackId))
                if (!stack) {
                    return
                }
                stack.blockRuntimeState.view = {
                    viewId: id,
                    scope,
                    selectedMode: SelectedMode.CURRENT_PAGE,
                    selectedIds: ids
                }
            })
        },
        [id, pageId, scope, setPageStack, stackId]
    )

    const viewPagination = useMemo(
        () => ({
            currentPage,
            pageSize,
            rowTotal
        }),
        [currentPage, pageSize, rowTotal]
    )

    return useMemo(() => {
        if (!pointer) {
            return null
        }

        if ((loading && firstRef.current) || !records) {
            firstRef.current = false
            viewIdRef.current = id
            return (
                <CenteredWrapper>
                    <Loading />
                </CenteredWrapper>
            )
        }

        if (viewIdRef.current !== id && !(initGroup.groupByFieldId && tabList.length === 0)) {
            return (
                <CenteredWrapper>
                    <Loading />
                </CenteredWrapper>
            )
        }

        if (!dataSource) {
            return null
        }
        viewIdRef.current = id
        firstRef.current = false

        const paddingStyle =
            viewType === 'custom' ? getPaddingStyle(baseBreakpoint.viewLayout?.viewPadding) : { padding: 'var(--block-padding)' }

        return (
            <AnimationDecorators
                blockId={id}
                animation={baseBreakpoint.animation}
                data-block-type={viewType}
                data-stop-action-propagation
                style={{ width: '100%', height: '100%', ...paddingStyle, overflow: baseBreakpoint.size.overflow }}
            >
                <SCxViewContainer ref={ref}>
                    {breakPoint ? (
                        <ViewBlockTool
                            appId={appId}
                            scope={scope}
                            dataSource={dataSource}
                            dataSourceList={dataSourceList}
                            tabList={tabList}
                            tabValue={tabValue}
                            blockData={blockData}
                            quickFilterData={quickFilterData}
                            viewIndependentDataOmitRecord={viewIndependentDataOmitRecord}
                            // selectedRecords={selectedRecords}
                            // exportTemplateList={exportTemplateList}
                            // selectedMode={selectedMode}
                            sortCache={sortCache}
                            groupCache={initGroup}
                            tablePropsCache={tablePropsCache}
                            cachedKanbanSort={kanbanColumnsSortCache}
                            previewType={previewType}
                            onTabChange={handleTabChange}
                            onRecordAdd={handleRecordAdd}
                            onSearch={handleSearch}
                            // onSelectModeChange={handleSelectModeChange}
                            onFilter={handleQuickFilter}
                            // onRecordDelete={handleRecordDelete}
                            onChangeSorter={handleSorterChange}
                            onChangeDisplay={handleDisplayChange}
                            onChangeGroup={handleGroupChange}
                            onAppend={handleAppend}
                            // onExport={handleExport}
                            // onPrint={handlePrintByTemplate}
                            onToolbarActionTrigger={handleAction}
                            onChangePageNum={handleChangePageNum}
                            onRenderButtonTitle={handleRenderTitle}
                            onChangeCachedKanbanSort={setKanbanColumnsSortCache}
                        >
                            <ViewBlockRender
                                dataSource={dataSource}
                                blockData={blockData}
                                dataSourceList={dataSourceList}
                                records={records}
                                previewType={previewType}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                selectedRecords={selectedIds}
                                blockWidth={blockWidth}
                                breakPoint={breakPoint}
                                pagination={viewPagination}
                                tablePropsCache={tablePropsCache}
                                tableColumnCache={tableColumnCache}
                                cachedKanbanSort={kanbanColumnsSortCache}
                                onSelectedRecords={handleSelectedRecords}
                                onRecordClick={handleRecordClick}
                                onRecordEdit={handleRecordEditClick}
                                onRecordAdd={handleRecordAdd}
                                onRecordDelete={handleRecordDelete}
                                onAiGeneration={handleAiGeneration}
                                onRecordOperatorActionTrigger={handleAction}
                                onRecordClickedActionTrigger={handleAction}
                                onCellChange={handleValueChange}
                                onCellUpdate={handleCellUpdate}
                                onUpdateRecord={handleRecordUpdate}
                                onRenderButtonTitle={handleRenderTitle}
                                node={node}
                                onLoadMoreData={handleLoadMoreRecords}
                                onSelectModeChange={handleSelectModeChange}
                                onTableColumnWidthChange={handleTableColumnWidthChange}
                                onChangeCachedKanbanSort={setKanbanColumnsSortCache}
                            />
                        </ViewBlockTool>
                    ) : null}
                </SCxViewContainer>
            </AnimationDecorators>
        )
    }, [
        pointer,
        loading,
        records,
        id,
        initGroup,
        tabList,
        dataSource,
        viewType,
        baseBreakpoint.viewLayout?.viewPadding,
        baseBreakpoint.animation,
        baseBreakpoint.size.overflow,
        ref,
        breakPoint,
        appId,
        scope,
        dataSourceList,
        tabValue,
        blockData,
        quickFilterData,
        viewIndependentDataOmitRecord,
        sortCache,
        tablePropsCache,
        kanbanColumnsSortCache,
        previewType,
        handleTabChange,
        handleRecordAdd,
        handleSearch,
        handleQuickFilter,
        handleSorterChange,
        handleDisplayChange,
        handleGroupChange,
        handleAppend,
        handleAction,
        handleChangePageNum,
        handleRenderTitle,
        setKanbanColumnsSortCache,
        selectedIds,
        blockWidth,
        viewPagination,
        tableColumnCache,
        handleSelectedRecords,
        handleRecordClick,
        handleRecordEditClick,
        handleRecordDelete,
        handleAiGeneration,
        handleValueChange,
        handleCellUpdate,
        handleRecordUpdate,
        node,
        handleLoadMoreRecords,
        handleSelectModeChange,
        handleTableColumnWidthChange
    ])
}

export default ViewBlockController
