import { Empty, IconFont, tinyButtons } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import type { MessageListData } from '../../types'

interface NotificationListProps {
    data?: MessageListData[]
    onNavigateTo?: (id: string, auditStatus?: 'agree' | 'reject') => void
}

const SCxMessageListWrapper = styled.div`
    max-height: 100%;
    overflow-y: auto;

    ${tinyButtons}
`

const SCxMessageWrapper = styled.div`
    display: flex;
    padding: 20px 12px;
    font-size: var(--font-size-normal);
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-200);
    }
`

const SCxMessageIconWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
`

const SCxMessageIcon = styled(IconFont)`
    color: var(--color-main);
    font-size: 20px;
`

const SCxMessageContentWrapper = styled.div`
    flex: 1;
`

const SCxMessageContentTitle = styled.div`
    margin-bottom: 4px;
`

const SCxMessageContentDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

const SCxMessageContentDetail = styled.div`
    border-radius: 4px;
    width: 100%;
    margin: 12px 0;
    padding: 10px;
    background-color: var(--color-gray-100);
`

const SCxMessageContentDetailItem = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`

const SCxMessageContentDetailItemLabel = styled.div`
    width: 90px;
    margin-right: 12px;
    color: var(--color-gray-500);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const NotificationList: React.FC<NotificationListProps> = ({ data, onNavigateTo }) => {
    if (data?.length === 0) {
        return (
            <Empty
                style={{ padding: '20px 0' }}
                description="暂无消息"
                icon={<IconFont type="Bell" size={24} color="var(--color-gray-500)" style={{ marginBottom: 8 }} />}
            />
        )
    }

    const renderMessageContent = (data: MessageListData) => {
        if (data.type === 'STATION_MESSAGE') {
            const { id, createdTime, appName, title, content } = data

            return (
                <SCxMessageWrapper key={id} onClick={() => onNavigateTo?.(id)}>
                    <SCxMessageIconWrapper>
                        <SCxMessageIcon type="Bell" style={{ color: 'var(--color-black)' }} />
                    </SCxMessageIconWrapper>
                    <SCxMessageContentWrapper>
                        <SCxMessageContentTitle>{title}</SCxMessageContentTitle>
                        <SCxMessageContentDescription>
                            {createdTime}
                        </SCxMessageContentDescription>

                        <SCxMessageContentDetail>
                            <SCxMessageContentDetailItem>
                                <SCxMessageContentDetailItemLabel>{content}</SCxMessageContentDetailItemLabel>
                            </SCxMessageContentDetailItem>
                        </SCxMessageContentDetail>
                    </SCxMessageContentWrapper>
                </SCxMessageWrapper>
            )
        }
    }

    return <SCxMessageListWrapper>{data?.map(d => renderMessageContent(d))}</SCxMessageListWrapper>
}
