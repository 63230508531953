import type { RecordLikeProtocol } from '@lighthouse/core'
import { WechatPayType } from '@lighthouse/core'
import {
    type ActionFlowNode,
    type WeChatPayActionConfig,
    getSearchParams,
    getWechatPayType
} from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import { sendWxRoot } from '@/hooks/useWxAuthRoot'
import * as srv from '@/services'
import { type WechatPaySearch, PayTriggerType } from '@/services/types'

// import { openWechatAuthModal } from '@/utils'
import { wechatPayEmitter } from '../event'

export const handleWechatPay = async (node: ActionFlowNode<WeChatPayActionConfig>, excParams: AnyObject) => {
    const {
        id,
        data: { config }
    } = node
    const { workflowId, actionEvents, clickTriggerNodeParams, getCurrentPageDeps, userRecord, isFormAction, dataSourceList } = excParams
    const pageId = getCurrentPageDeps()?.pageId ?? ''
    if (!config) {
        throw new Error('配置未完成')
    }
    if (!pageId) {
        throw new Error('页面id异常')
    }
    const { operationType, payConfig } = config
    const redirect = window.location.href
    if (!userRecord?.record?.id) {
        window.location.href = `/account/login?redirect=${encodeURIComponent(redirect)}`
        throw new Error('微信支付需要登录')
    }
    const weChatPayType = getWechatPayType()
    // const regArr = window.location.pathname.match(PAGE_URL_REG)
    // const pageUrl = !regArr || regArr.length === 1 ? window.location.pathname : `/${regArr.slice(0, -1).join('/')}`
    // 表单使用数据时计算关闭当前页面后的路径
    // const redirect = isFormAction ? `${window.location.origin}${pageUrl}` : window.location.href
    // 微信支付
    if (operationType === 'PAY') {
        if (weChatPayType === WechatPayType.JSAPI) {
            const isPermissionGranted = await srv.checkWxOfficialRoot()
            // 微信支付未授权
            if (!isPermissionGranted) {
                // openWechatAuthModal(() => {
                sendWxRoot()
                // })
                throw new Error('disabled')
            }
        }
        const { nodeId = '', autoRedirectAfterPayment = true } = payConfig

        // const resolveCallbackWorkflow =callbackWorkflow ? {

        // }:
        const record = excParams[nodeId]?.record
        if (!record) {
            throw new Error('上游节点配置未完善')
        }
        // const dataSource = find(ds => ds.id === record.dsId, dataSourceList as DataSourceAbstract[])
        const res = await srv.invokeWechatPay({
            scene: {
                type: PayTriggerType.WORKFLOW,
                workflowConfig: {
                    workflowId,
                    nodeId: id
                }
            },
            dsId: record.dsId,
            recordId: record.id,
            pageId,
            payType: weChatPayType,
            // amountFieldId,
            // descFieldId,
            // succeedFieldSettings,
            // callbackWorkflow
            // failedFieldSettings: failedFieldSettings.map(field => ({
            //     ...field,
            //     value: generateVariableValue({ innerType: schema[field.fieldId]?.innerType || 'TEXT', jsonContent: field.value, extraParams: excParams }) as AnyObject
            // }))
        })
        if (!res) {
            throw new Error(`获取微信支付订单异常`)
        }
        const { outTradeNo, jsApiResponse, nativeResponse } = res
        switch (weChatPayType) {
            case WechatPayType.H5: {
                break
            }
            case WechatPayType.JSAPI: {
                const { appId, timeStamp, nonceStr, paySign, packageVal, signType } = jsApiResponse
                const newSearch: WechatPaySearch = {
                    appId,
                    timeStamp: timeStamp.toString(),
                    nonceStr,
                    paySign,
                    packageVal,
                    signType,
                    pageId,
                    outTradeNo,
                    autoRedirectAfterPayment: Number(autoRedirectAfterPayment),
                    redirect: encodeURIComponent(redirect)
                }
                const search = getSearchParams(newSearch)
                actionEvents.navigate(`/pay/?${search}`)
                break
            }
            case WechatPayType.NATIVE: {
                const { codeUrl } = nativeResponse
                const newSearch = {
                    pageId,
                    outTradeNo,
                    codeUrl,
                    autoRedirectAfterPayment: Number(autoRedirectAfterPayment),
                    redirect: encodeURIComponent(redirect)
                }
                const search = getSearchParams(newSearch)
                actionEvents.navigate(`/pay/?${search}`)
                break
            }
            default: {
                break
            }
        }

        const newRecord = await new Promise<RecordLikeProtocol>((resolve, reject) => {
            wechatPayEmitter.on(`wechatPayCheck-${outTradeNo}`, async status => {
                const res = await srv.getRecord({ appId: record.appId, envId: record.envId, dsId: record.dsId, recordId: record.id, pageId })
                if (res.record) {
                    resolve(res.record)
                }
                wechatPayEmitter.off(`wechatPayCheck-${outTradeNo}`)
            })
        })

        // 更新行以后，需要通知对应订阅更新数据
        // pageStackPubSub.emit(`${record.dsId}-ADD`, newRecord)

        return { record: newRecord }
    }

    return {}
}
