import { SubFormBlock } from '@lighthouse/block'
import { Toast } from '@lighthouse/bui'
import { type SubFormBlockAbstract, type ViewRecordOperateProtocol, RecordOpenType } from '@lighthouse/core'
import {
    aliyunVideoProtocolList,
    fieldFileFilter,
    fileMaxUploadSize,
    fileSuffixRegex,
    getFileSizeToMB,
    getUrlNameByOpenType,
    useAtomData,
    useFormModuleSubForm
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useAsync } from 'react-use'

import { languageAtom } from '@/atoms/application/state'
import { pageAtomFamily, pageStackAtomFamily, pageStackOfFieldBlockAtom } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId, usePreview } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockDefaultValueRender } from '@/hooks/useFieldBlockDefaultValueRender'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { useFieldInputRenderLabel } from '@/hooks/useParentRecord'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

interface SubFormBlockControllerProps {
    blockData: SubFormBlockAbstract
    onBlockChange?: (values: SubFormBlockAbstract, origin: SubFormBlockAbstract) => Promise<void> | void
}

const SubFormBlockController: React.FunctionComponent<SubFormBlockControllerProps> = ({ blockData, onBlockChange }) => {
    const {
        config: { columns = [], pointer = '' },
        id: blockId
    } = blockData

    const onVariableValueRender = useFieldBlockDefaultValueRender(pointer)
    const onRenderLabel = useFieldInputRenderLabel()

    const {
        initValue,
        defaultValue: dataList,
        subFormErrors: errors,
        onChangeSubForm,
        onSubFormInputChange,
        onSubFormInputBlur
    } = useFormModuleSubForm(blockId)
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId)
    const previewType = usePreview()
    const navigate = useNavigate()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const dataSource = useDataSource(appId, envId, pointer)
    const language = useAtomData(languageAtom)
    const fieldBlockValueMap = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )
    const { handleRenderTitle } = useRichTextToTitle()

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                navigate(`./${getUrlNameByOpenType(openType)}/${page}`, { relative: 'path' })
            }
        },
        [navigate]
    )

    const handleFetchSmsCode = useCallback((mobile: string) => srv.getVerifyCode({ mobile, blockId, pageId }), [blockId, pageId])

    /** 获取关联数据源数据 start */
    const { value: relativeDataSourceConfig } = useAsync(
        () =>
            Promise.all(
                columns.map(async column => {
                    const { config, id } = column
                    const { inputType = 'text', fieldPointer = '', initialValue } = config ?? {}
                    const initValue = await onVariableValueRender({
                        id,
                        dsId: pointer,
                        inputType,
                        fieldId: fieldPointer,
                        initialValue,
                        config
                    })
                    return { config, value: initValue.value }
                })
            ),
        [columns, onVariableValueRender, pointer]
    )
    const { onFetchDataSource, onLoadMoreData, relativeDataSource, onFetchCascadeOptions, onFetchPersonOptions, onGetInitRecord } =
        useFieldBlockMethods({
            fieldBlocks: relativeDataSourceConfig,
            fieldBlockValueMap
        })
    /** 获取关联数据源数据end */

    const uploadyOptions = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(appId),
                fileFilter: fieldFileFilter
            }
        }),
        [appId, pageId, pageName]
    )

    const richTextUploadOptions = useMemo(() => uploadManagerInAppParams(appId), [appId])

    const videoUploadyOption = useMemo(() => {
        return {
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(appId),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }
                        const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                        if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                            Toast.error('视频格式错误，请上传 MP4 格式的视频')
                            return false
                        }
                        return true
                    }
                    return true
                }
            }
        }
    }, [appId, pageId, pageName])

    return (
        <SubFormBlock
            appId={appId}
            envId={envId}
            dataList={dataList}
            isValid={false}
            blockData={blockData}
            previewType={previewType}
            dataSource={dataSource}
            relativeDataSource={relativeDataSource}
            dataSourceList={dataSourceList}
            language={language}
            initData={initValue}
            errors={errors}
            uploadyOptions={uploadyOptions}
            richTextUploadOptions={richTextUploadOptions}
            videoUploadyOption={videoUploadyOption}
            onChange={onChangeSubForm}
            onBlockChange={onBlockChange}
            onInputChange={onSubFormInputChange}
            onVariableValueRender={onVariableValueRender}
            onOpenPage={handleOpenPage}
            onFetchSmsCode={handleFetchSmsCode}
            onFetchCascadeOptions={onFetchCascadeOptions}
            onFetchDataSource={onFetchDataSource}
            onLoadMoreData={onLoadMoreData}
            onFetchPersonOptions={onFetchPersonOptions}
            onRenderLabel={onRenderLabel}
            onInputBlur={onSubFormInputBlur}
            onGetInitRecord={onGetInitRecord}
        />
    )
}

export default SubFormBlockController
