import type {
    BlockID,
    ChartLikeProtocol,
    CollectEventWriteType,
    DataSourceAbstract,
    DataSourceIdentity,
    FieldIdWithValue,
    FieldInputValue,
    FieldValue,
    FilterCommonCondition,
    FilterFormType,
    FormRule,
    PaginationProtocol,
    RecordAbstract,
    RecordLikeProtocol,
    Sorter,
    StripeAction,
    SubProcessResolvedArg,
    ViewOptions,
    WeChatPayAction,
    WechatPayType
} from '@lighthouse/core'
import type { LoginAuthType, PageContentRes, WeChatPublicAccountItem } from '@lighthouse/shared'

// 此文用于定于前后端数据转换过程中需要的一些类型
// export type UpdatePagePayload = Partial<Omit<PageAbstract, 'id' | 'nodes'>> & {
//     id: string
//     blockNodes?: LayoutNode[]
// }

export type LoginProtocolPayload = {
    verificationCode?: string
    authType: LoginAuthType
    email?: string
    appId: string
    mobile?: string
    mark?: string
    versionId: string
}

export type RelationRecords = Record<BlockID, Record<BlockID, Pick<RecordLikeProtocol, 'content' | 'id' | 'dsId'>>>

export type RelationDataSources = Record<string, DataSourceAbstract>

export type GroupRecordCountPayload = {
    appId: string
    viewId: string
    isSyncComponent?: boolean
    fieldId: string
    filter: FilterFormType | undefined
    quickFilters?: FilterCommonCondition[]
    linkFilter?: FilterFormType
    search?: string
    pageId: string
    abort?: AbortController
}

export type GetDsCountPayload = {
    appId: string
    dsId: string
    search?: string
    filter?: FilterFormType
    abort?: AbortController
    pageId: string
    currentRecordId?: string
    parentRecordId?: string
}

export type GetDsGraphViewPayload = {
    blockId: string
    isSyncComponent?: boolean
    pageId: string
    currentRecordId?: string
    parentRecordId?: string
}

export type UpdateCellPayload = DataSourceIdentity & {
    id: string
    fieldId: string
    pageId: string
    content: FieldValue
}

export type ViewSettingParams = {
    filter: FilterFormType | undefined
    quickFilters?: FilterCommonCondition[]
    linkFilter?: FilterFormType
    sorts?: Sorter[]
}

/** 表单页面创建数据 */
export type CreateRecordFromFormData = { dsId: string; fieldDTOS: FieldIdWithValue[] }

export type ChartData = {
    datasource: DataSourceAbstract
    chart: ChartLikeProtocol
}

export type RecordPayload = {
    appId: string
    dsId: string
    recordId: string
    pageId: string
}

/**
 *
 * wechat 相关接口类型
 */
export type WechatSignature = {
    appId: string
    nonceStr: string
    signature: string
    timestamp: number
}

export type WechatShareDataParams = {
    pageId: string
    currentRecordId: string | undefined
    parentRecordId: string | undefined
}

export type WechatShareResult = {
    title: string
    subtitle: string
    icon: string
}

export type OtherPlatform = {
    name: string
    config: WeChatPublicAccountItem
}

export enum PerfectInfoCode {
    noPerfectInfo,
    bindMobile,
    needPerfectInfo,
    mobileAndPerfectInfo
}

export type InvokeWechatPayResult = {
    outTradeNo: string
    amount?: number
    jsApiResponse: {
        // 仅在jsapi支付的时候返回
        appId: string
        mchId: string
        description: string
        notifyUrl: string
        packageVal: string
        openId: string
        timeStamp: number
        nonceStr: string
        signType: string
        paySign: string
    }
    h5Response: {
        // 仅在h5支付的时候返回
        codeUrl: string
    }
    nativeResponse: {
        // 仅在native支付的时候返回
        codeUrl: string
    }
}

export enum PayTriggerType {
    INDEPENDENT = 'INDEPENDENT', // 独立动作
    WORKFLOW = 'WORKFLOW' // 工作流
}
export type IndependentPay = {
    type: PayTriggerType.INDEPENDENT
    independentConfig: {
        blockId: string
        actionId: string
    }
}

export type WorkflowPay = {
    type: PayTriggerType.WORKFLOW
    workflowConfig: {
        workflowId: string
        nodeId: string
    }
}

export type WechatPayPayload = {
    scene: IndependentPay | WorkflowPay
    dsId: string
    recordId: string
    payType: WechatPayType
    pageId: string
}

export type StripePayPayload = {
    scene: IndependentPay | WorkflowPay
    dsId: string
    recordId: string
    pageId: string
}

// export type WechatRefoundPayload = {
//     dsId: string
//     recordId: string
//     pageId: string
//     refundAmountFieldId: string
// } & Pick<
//     WeChatPayAction['data']['wechatPay']['refundConfig'],
//     'succeedFieldSettings' | 'outTradeNoFieldId' | 'reasonFieldId'
// >

export type WechatPaySearch = {
    appId?: string
    timeStamp?: string
    nonceStr?: string
    paySign?: string
    packageVal?: string
    signType?: string
    pageId: string
    redirect: string
    outTradeNo: string
    codeUrl?: string
    autoRedirectAfterPayment: number
}

export type StripePayResult = {
    expiresAt: number
    link?: string
    outTradeNo: string
    clientSecret: string
}

export type StripePaySearch = StripePayResult & {
    redirect: string
    pageId: string
    autoRedirectAfterPayment: number
}

/**
 * 开始   接口类型定义（新）
 *
 */

// --------------------------------------------------------------------
// payload
// --------------------------------------------------------------------

export type RecordWithPageIdPayload = {
    appId: string
    envId: string
    dsId: string
    recordId: string
    pageId: string
}

export type SubmitFormPayload = {
    appId: string
    envId: string
    dsId: string
    blockId: string
    pageId: string
    currentRecordId?: string
    parentRecordId?: string
    isWeChatBrowser?: boolean
    fields: {
        fieldId: string
        isCheckRepeat?: boolean
        value: FieldInputValue
    }[]
    validator: {
        rules?: FormRule[]
        codeValidator?: Record<string, string>
    }
    subFormRecord?: { subFormId: string; subFormContent: { recordId: string; columns: { columnId: string; value: FieldInputValue }[] }[] }[]
}

export type CreateRecordPayload = {
    appId: string
    envId: string
    dsId: string
    id: string
    pageId: string
    content?: RecordLikeProtocol['content']
    beforeId?: string
}

export type GetRecordByFilterPayload = {
    appId: string
    envId: string
    dsId: string
    pageId: string
    filter?: FilterFormType
    sorts?: Sorter[]
    currentRecordId?: string
    parentRecordId?: string
}

export type GetDataSourcePayload = {
    appId: string
    envId: string
    dsId: string
    pagination: PaginationProtocol['pagination']
    search?: string
    filter?: FilterFormType
    sorts?: Sorter[]
    disabledFilter?: FilterFormType
    abort?: AbortController
    pageId: string
    currentRecordId?: string
    parentRecordId?: string
}

export type GetViewByBlockPayload = {
    appId: string
    envId: string
    viewId: string
    isSyncComponent?: boolean
    pageId: string
    pagination: PaginationProtocol['pagination']
    params?: ViewSettingParams
    search?: string
    currentRecordId?: string
    parentRecordId?: string
    abort?: AbortController
}

export type EventCollectPayload = {
    appId: string
    writeType: CollectEventWriteType
    dsId?: string
    fieldValues?: FieldIdWithValue[]
    subProcessId?: string
    args?: SubProcessResolvedArg[]
}

// --------------------------------------------------------------------
// res
// --------------------------------------------------------------------
export type DataSourceRes = Omit<DataSourceAbstract, 'appId' | 'envId'>

export type RecordRes = Omit<RecordLikeProtocol, 'appId' | 'envId' | 'dsId'>

/** 记录页面内容返回 */
export type PageRecordRes = Omit<PageContentRes, 'recordPage' | 'pageConfig'> & {
    dataSource: DataSourceRes
    record: RecordRes
    fieldBlockCellVoMap?: Record<
        string, // 字段 block id
        {
            dataSource: DataSourceAbstract
            record: RecordAbstract
        }
    >
}

export type SubmitFormRes = {
    record?: RecordRes
    repeatFieldIds?: string[]
    failureMessage?: string
}

export type UserDataSourceRes = {
    datasource?: DataSourceRes
    record?: RecordRes
}

export type DataSourceAndRecordsRes = {
    datasource: DataSourceRes
    records: RecordRes[]
}

export type DataSourceAndRecordsWithConfigRes = {
    datasource: DataSourceRes
    records: RecordRes[]
    config: {
        view: ViewOptions
        relatedView: ViewOptions
    }
}

// --------------------------------------------------------------------
// result
// --------------------------------------------------------------------
export type PageRecordResult = Omit<PageContentRes, 'recordPage' | 'pageConfig'> & {
    dataSource: DataSourceAbstract
    record: RecordLikeProtocol
    fieldBlockCellVoMap?: Record<
        string, // 字段 block id
        {
            dataSource: DataSourceAbstract
            record: RecordAbstract
        }
    >
}

export type UserDataSourceResult = {
    datasource?: DataSourceAbstract
    record?: RecordLikeProtocol
}

export type DataSourceAndRecordsResult = {
    datasource: DataSourceAbstract
    records: RecordLikeProtocol[]
}

export type DataSourceAndRecordsWithConfigResult = {
    datasource: DataSourceAbstract
    records: RecordLikeProtocol[]
    config: {
        view: ViewOptions
        relatedView: ViewOptions
    }
}

export type BindAccountStatus = 'SUCCESS' | 'ACCOUNT_BOUND' | 'FAIL'

