import { FloatingTree } from '@floating-ui/react'
import { useContainerBlockContext } from '@lighthouse/block'
import type { ContainerBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import {
    checkActionEvent,
    FloatBoxProvider,
    getDefaultAction,
    getWithScopeId,
    useAtomAction,
    useRegisterBlockListener
} from '@lighthouse/shared'
import React, { Suspense, useCallback, useMemo } from 'react'

import { pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useFloatBlock } from '@/hooks/useFloatBlock'

const ContainerBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.ContainerBlock })))

interface ContainerControllerProps {
    blockData: ContainerBlockAbstract
    node: FlowLayoutNode
    disabled?: boolean
    children?: React.ReactNode
    onBlockChange?: (values: ContainerBlockAbstract, origin: ContainerBlockAbstract) => Promise<void> | void
}

const ContainerController = ({ blockData, onBlockChange, ...rest }: ContainerControllerProps) => {
    const { id, config } = blockData
    const { action, shown, floatPointer = '' } = config
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { scope } = useContainerBlockContext()

    const withScopeId = getWithScopeId(id, scope)

    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const independentAction = useMemo(() => {
        if (!action) {
            return getDefaultAction()
        }
        return action
    }, [action])
    const { handleActionTrigger } = useActionTrigger(id)

    const onClickAction = (ev: React.MouseEvent<HTMLElement>) => {
        if (independentAction.type === 'none') {
            return
        }

        if (checkActionEvent(ev)) {
            handleActionTrigger(independentAction)
        }
    }

    const handleViewChange = useCallback(
        (view: string) => {
            setPageStack(draft => {
                const pageStack = draft.find(equalPageStack(pageId, stackId))
                if (!pageStack) {
                    return
                }

                pageStack.blockRuntimeState.container = {
                    ...pageStack.blockRuntimeState.container,
                    [withScopeId]: {
                        ...pageStack.blockRuntimeState.container?.[withScopeId],
                        currentView: view
                    }
                }
            })
        },
        [setPageStack, pageId, stackId, withScopeId]
    )

    useRegisterBlockListener(withScopeId, 'container', {
        switchPanel: payload => {
            payload && handleViewChange(payload.switchPanelId)
        },
        open: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: true } }
            onBlockChange?.(newBlockData, blockData)
        },
        close: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: false } }
            onBlockChange?.(newBlockData, blockData)
        }
    })

    const provideValue = useFloatBlock(floatPointer, rest.node.isRootSyncNode ? rest.node.id : scope)

    return (
        <Suspense fallback={<div />}>
            <FloatingTree>
                <FloatBoxProvider {...provideValue}>
                    <ContainerBlock
                        {...rest}
                        shown={shown}
                        data-stop-action-propagation={independentAction.type === 'none' ? undefined : true}
                        onClick={onClickAction}
                        style={{ cursor: independentAction.type === 'none' ? undefined : 'pointer' }}
                    />
                </FloatBoxProvider>
            </FloatingTree>
        </Suspense>
    )
}

export default ContainerController
