import { isIosPlatform, isWechatBrowser } from '@lighthouse/tools'
import wx from 'weixin-js-sdk'

import { getWechatSignature } from '@/services'

/** ios微信浏览器设置签名时，需要传入进入页面时的地址 */
export const FIRST_URL_FOR_IOS_WECHAT = 'FIRST_URL_FOR_IOS_WECHAT'
/**
 * 微信 SDK 初始化
 * 要确保 url 正确，否则会导致签名失败
 * @export
 */
export async function initWxSDK() {
    const isWechat = isWechatBrowser()
    const isIos = isIosPlatform()

    if (!isWechat) {
        return
    }

    const defaultUrl = window.location.href.split('#')[0]
    const url = isIos ? sessionStorage.getItem(FIRST_URL_FOR_IOS_WECHAT) || defaultUrl : defaultUrl
    //
    const wechatSignature = await getWechatSignature(encodeURIComponent(url))

    if (!wechatSignature) {
        return
    }
    const { appId, nonceStr, signature, timestamp } = wechatSignature

    const { VITE_SENTRY_ENVIRONMENT } = import.meta.env

    const isLocalDev = !VITE_SENTRY_ENVIRONMENT

    wx.config({
        // 暂时修改
        debug: isLocalDev,
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData', 'scanQRCode', 'previewImage']
    })

}

interface ShareWechatParams {
    title: string
    desc: string
    imgUrl: string
    // onShareMessageSuccess?: () => void
}

export function shareWechatMessageData({ title, desc, imgUrl }: ShareWechatParams) {
    const isWechat = isWechatBrowser()

    if (!isWechat) {
        return
    }

    // wx.ready 注意要在 wechat sdk 初始化后调用
    wx.ready(() => {
        // wx.onMenuShareAppMessage({
        //     title, // 分享标题
        //     desc, // 分享描述
        //     link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //     imgUrl,
        //     success() {
        //         // 用户点击了分享后执行的回调函数
        //         onShareMessageSuccess?.()
        //     },
        //     cancel() {
        //         // 取消
        //     },
        //     fail(res) {
        //         // Toast.error('分享调用失败');
        //     },
        // });

        // wx.updateAppMessageShareData({
        //     title,
        //     desc,
        //     link: window.location.href.split('#')[0],
        //     imgUrl,
        //     success() {
        //         // 设置成功
        //     },
        //     fail() {
        //         // 设置失败
        //     }
        // })

        wx.updateAppMessageShareData({
            title,
            desc,
            link: window.location.href.split('#')[0],
            imgUrl,
            success() {
                // 设置成功
            },
            fail() {
                // 设置失败
            }
        })

        wx.updateTimelineShareData({
            title,
            link: window.location.href.split('#')[0],
            imgUrl,
            success() {
                // 设置成功
            },
            fail() {
                // 设置失败
            }
        })
    })

    wx.error((res) => {
        const { errMsg } = res
        // eslint-disable-next-line no-console
        console.log(`微信SDK初始化失败: ${errMsg}`);
    });
}
