import { IconBlock } from '@lighthouse/block'
import type { IconBlockAbstract } from '@lighthouse/core'
import React from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'

interface IconBlockProps {
    blockData: IconBlockAbstract
}

const IconBlockController: React.FC<IconBlockProps> = ({ blockData }) => {
    const { id } = blockData
    const { handleActionTrigger } = useActionTrigger(id)

    return <IconBlock onActionTrigger={handleActionTrigger} blockData={blockData} />
}

export default IconBlockController
