import { Empty, tinyButtons } from '@byecode/ui'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import type {
    MessageDetailStationMessageData,
    MessageEvents,
    MessageListData} from '../../types'

interface NotificationFormContentProps {
    appId?: string
    data: MessageListData
    auditStatus?: 'agree' | 'reject'
    messageEvents?: MessageEvents
    isMobile?: boolean
    onClose?: () => void
}

const SCxNotificationFormContentWrapper = styled.div`
    padding: 0 8px;
`

const SCxNotificationContent = styled.div`
    padding: 10px;
    background-color: var(--color-gray-100);
    border-radius: 4px;
    margin-top: 12px;
`

const SCxNotificationDetailContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const SCxNotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 14px 16px;
    border-bottom: 1px solid var(--color-gray-100);
`

const SCxNotificationHeaderTitle = styled.div`
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
`

const SCxMessageContentDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

const SCxMessageDetailContentWrapper = styled.div`
    flex: 1;
    padding: 20px 0;
    overflow-y: auto;

    ${tinyButtons}
`

const StationMessageDetail: React.FC<NotificationFormContentProps> = ({ data: message, messageEvents }) => {
    const { id } = message
    const [data, setData] = useState<MessageDetailStationMessageData>()

    const fetchMessageDetail = useCallback(async () => {
        const res = await messageEvents?.onFetchMessageDetail?.(id)
        if (res) {
            setData(res )
        }
    }, [id, messageEvents])

    useEffect(() => {
        fetchMessageDetail()
    }, [fetchMessageDetail])

    if (!data) {
        return <Empty description="暂无消息" />
    }

    const { appName, createdTime, content, title } = data

    return (
        <SCxNotificationDetailContentWrapper>
            <SCxNotificationHeader>
                <SCxNotificationHeaderTitle>{title}</SCxNotificationHeaderTitle>
            </SCxNotificationHeader>
            <SCxMessageDetailContentWrapper>
                <SCxNotificationFormContentWrapper>
                    <SCxMessageContentDescription>
                        {createdTime} · 来自 {appName}
                    </SCxMessageContentDescription>
                    <SCxNotificationContent>{content}</SCxNotificationContent>
                </SCxNotificationFormContentWrapper>
            </SCxMessageDetailContentWrapper>
        </SCxNotificationDetailContentWrapper>
    )
}

export const NotificationDetailContent: React.FC<NotificationFormContentProps> = props => {
    const { data: message } = props

    return message.type === 'STATION_MESSAGE' ? <StationMessageDetail {...props} /> : null
}
