import { PageAbstract } from '@lighthouse/core'
import { activeState } from '@lighthouse/shared'

import * as srv from '@/services'

export const changeFavicon = (icon: string) => {
    if (!icon) {
        return
    }
    const favIconElement = document.querySelector<HTMLLinkElement>('link[rel="icon"]')
    if (favIconElement) {
        favIconElement.href = icon
        return
    }
    const createIconElement = document.createElement('link')
    createIconElement.rel = 'icon'
    createIconElement.href = icon
    document.head.append(createIconElement)
}

export const changeAppleTouchIcon = (params: { appId: string; icon: string; title: string; backgroundColor: string }) => {
    const { icon, appId } = params
    if (!icon) {
        return
    }
    const favIconElement = document.querySelector<HTMLLinkElement>('link[rel="apple-touch-icon"]')
    if (favIconElement) {
        favIconElement.href = icon
        // return
    } else {
        const createIconElement = document.createElement('link')
        // apple mobile
        createIconElement.rel = 'apple-touch-icon'
        createIconElement.href = icon
        document.head.append(createIconElement)
        // splash screen
        const splashScreenElement = document.createElement('link')
        splashScreenElement.rel = 'apple-touch-startup-image'
        splashScreenElement.href = icon
        document.head.append(splashScreenElement)
    }

    document
        .querySelector<HTMLElement>('#my-manifest-placeholder')
        ?.setAttribute('href', `/lighthouse/api/open/v1/${appId}/pwa/manifest.json?startUrl=${window.location.href}`)
}

export const activeRecordId = (viewId: string, recordId: string, cellDataKey: string) => {
    activeState.pushActiveViewRecord(viewId, recordId)
    const currentRecordKey = `[${cellDataKey}='${viewId}&${recordId}']`
    const recordBlockDom = document.querySelector(currentRecordKey)
    if (recordBlockDom) {
        recordBlockDom.classList.add('record-active')
    }
}

export const clearActiveRecordId = (cellDataKey: string) => {
    const { viewId, recordId } = activeState.clearActiveRecord() ?? {}
    if (!recordId || !viewId) {
        return
    }
    const currentRecordKey = `[${cellDataKey}='${viewId}&${recordId}']`
    const recordBlockDom = document.querySelector(currentRecordKey)

    if (recordBlockDom) {
        recordBlockDom.classList.remove('record-active')
    }
}
