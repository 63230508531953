import type { FloatBlockAbstract, FloatBlockConfig } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import {
    ApplicationPreviewEnum,
    findAllParentBlocksByType,
    findBlockById,
    findNormalOrSyncBlock,
    findParentBlockById,
    findParentBlockByType,
    getAllParentNodes,
    useAtomAction,
    useAtomData,
    useFloatBoxContext
} from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import { findLast, mergeDeepRight } from 'rambda'
import { useCallback, useEffect, useMemo } from 'react'

import { toggleFloatBoxAtom } from '@/atoms/application/action'
import { pageStackOfFloatBlockAtom, syncComponentsAtom } from '@/atoms/application/state'
import { pageBlocksAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { findFloatTree } from '@/atoms/utils/block'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'

import { usePreview } from './useApplication'

export function useFloatBlock(blockId: string, scope?: string) {
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const previewType = usePreview()
    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )
    const syncComponents = useAtomData(syncComponentsAtom)
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const { block, parentFloatId, rootFloatTargetId } = useAtomData(
        pageBlocksAtom(pageId),
        useCallback(
            d => {
                const floatBlock = findNormalOrSyncBlock({ id: blockId, scope }, d, syncComponents)
                return {
                    block: floatBlock?.type === BlockType.floatBox ? floatBlock : undefined,
                    parentFloatId: findParentBlockByType({
                        blocks: d,
                        blockRuntimeState,
                        syncComponents,
                        id: blockId,
                        filter: block => block.type === BlockType.floatBox
                    })?.id,
                    rootFloatTargetId: findAllParentBlocksByType({
                        id: blockId,
                        blocks: d,
                        syncComponents,
                        blockRuntimeState,
                        filter: block => block.type === BlockType.floatBox
                    })?.[0]?.id
                }
            },
            [blockId, blockRuntimeState, scope, syncComponents]
        )
    )
    const { opened } = useAtomData(
        pageStackOfFloatBlockAtom,
        useCallback(
            v => {
                return {
                    opened:
                    findFloatTree(v, d => d.blockId === blockId && stackId === d.stackId && (scope === undefined || scope === d.scope))
                    ?.open ?? false
                }
            },
            [blockId, scope, stackId]
        )
    )
    const { run: setPageStackOfFloatBlock } = useAtomAction(pageStackOfFloatBlockAtom)
    const { run: openFloat } = useAtomAction(toggleFloatBoxAtom)

    const checkRootChildren = useAtomCallback((get, set, id: string) => {
        const blocks = get(pageBlocksAtom(pageId))
        if (!rootFloatTargetId) {
            return true
        }
        const rootBlocks = findBlockById<FloatBlockAbstract>(rootFloatTargetId, blocks)
        return findBlockById(id, rootBlocks?.children ?? [])
    })

    const handleOpenChange = useCallback(
        (v: boolean, closeBlockId?: string) => {
            closeBlockId &&
                openFloat({
                    stackId,
                    blockId: closeBlockId,
                    parentFloatId,
                    scope,
                    open: v
                })
        },
        [openFloat, parentFloatId, stackId, scope]
    )

    const onClose = useCallback(() => {
        openFloat({
            stackId,
            blockId,
            scope,
            parentFloatId,
            open: false
        })
    }, [blockId, openFloat, scope, parentFloatId, stackId])

    const onOpen = useCallback(() => {
        openFloat({
            stackId,
            blockId,
            scope,
            parentFloatId,
            open: true
        })
    }, [blockId, openFloat, scope, parentFloatId, stackId])

    const onCloseAll = useCallback(() => {
        setPageStackOfFloatBlock(draft => {
            draft.splice(0)
        })
    }, [setPageStackOfFloatBlock])

    return useMemo(
        () => ({
            opened,
            // 移动端hover方式自动切换为点击
            config: isMobile
                ? mergeDeepRight<FloatBlockConfig>(block?.config ?? {}, {
                      overlay: {
                          showOn: 'click',
                          dismiss: 'click'
                      }
                  })
                : block?.config,
            blockId,
            checkRootChildren,
            previewType,
            // disableDismiss: true,
            // disabled: true,
            parentFloatId,
            rootFloatTargetId,
            onCloseAll,
            onOpenChange: handleOpenChange,
            onClose,
            onOpen
        }),
        [
            block?.config,
            blockId,
            checkRootChildren,
            handleOpenChange,
            isMobile,
            onClose,
            onCloseAll,
            onOpen,
            opened,
            parentFloatId,
            previewType,
            rootFloatTargetId
        ]
    )
}
