import type { ActionFlowNode, SendEmailActionConfig } from '@lighthouse/shared'
import { generateHtmlBySendEmail, generateVariableValue, generatorEmailBodyStyle, generatorEmailStyle, getEmails } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleSendEmail = async (node: ActionFlowNode<SendEmailActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    const { getCurrentPageDeps, palettes } = excParams
    const pageId = getCurrentPageDeps()?.pageId ?? ''

    if (!config) {
        return excParams
    }
    const { senderAccountId, recipients, cc, enableCc, title, background, padding, body } = config

    const titleValue = generateVariableValue({ innerType: 'TEXT', jsonContent: title, extraParams: excParams })

    const html = generateHtmlBySendEmail({
        config,
        excParams
    })

    const recipientsValue = getEmails(recipients, excParams)
    const ccValue = enableCc ? getEmails(cc, excParams) : []

    await srv.activeSendEmailAction({
        senderAccountId,
        recipients: recipientsValue,
        cc: ccValue,
        title: Array.isArray(titleValue) ? '' : String(titleValue),
        content: html,
        pageId
    })

    return {
        senderAccountId,
        recipients: recipientsValue,
        cc: ccValue,
        title: titleValue,
        content: ''
    }
}
