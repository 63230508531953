import { hideScrollBar } from '@byecode/ui'
import type { RecordLikeProtocol } from '@lighthouse/core'
import { type VariableADTvalue, RecordOpenType } from '@lighthouse/core'
import {
    combineBackgroundStyle,
    getBackgroundStyle,
    getVeinsStyle,
    LayoutEngine,
    PAGE_SCROLL_PARENT_CONTENT,
    PageContainerProvider,
    transformBlock2FlowLayoutNode,
    useAtomData,
    useFillPickerContext,
    useManualSetMediaQueryRemBase
} from '@lighthouse/shared'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { syncComponentsAtom, transformSyncComponentsAtom } from '@/atoms/application/state'
import { pageAtomFamily, pageBlocksAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/context/PageContext'
import { useCurrentAppId, usePreview } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'

import { PageSuspendPagination } from '../PageSuspendPagination'
import { BlockRender } from './BlockRender'

const PageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const ScrollerArea = styled.div`
    /* background-color: var(--color-white); */
    /* overflow: hidden auto; */
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    ${hideScrollBar}
`

interface PageContentProps {
    loading?: boolean
}

export const PageContent = forwardRef<HTMLDivElement, PageContentProps>((props, ref) => {
    const appId = useCurrentAppId()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const isRootPage = pageId === rootPageId
    const endPoint = useManualSetMediaQueryRemBase()
    const previewType = usePreview()

    const pageBreakpointConfig = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.breakPoint, [])
    )

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(transformSyncComponentsAtom)
    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )
    const isPageOpenedAsPage = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(s => s?.stackDisplayType === RecordOpenType.page, [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const getIsVisible = useVisibilityFilter({ prev, curr })

    const flowNodes = useMemo(
        () => transformBlock2FlowLayoutNode({ blocks: pageBlocks, syncComponents, blockRuntimeState, getIsVisible, previewType }),
        [blockRuntimeState, getIsVisible, pageBlocks, previewType, syncComponents]
    )

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const { renderLabel } = useVariableValueRender(pageId, prev.recordId, curr.recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined, record?: RecordLikeProtocol) => renderLabel(value, { useFileUrl: true, viewRecord: record }),
        [renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    const background = isRootPage ? undefined : pageBreakpointConfig?.design?.background

    const { palettes } = useFillPickerContext()

    const backgroundStyle: React.CSSProperties = useMemo(() => {
        const styles = {
            ...combineBackgroundStyle([
                getBackgroundStyle(appId, background, palettes, parseBackgroundVariableImage),
                getVeinsStyle(pageBreakpointConfig?.design?.veins, palettes)
            ])
        }

        return {
            ...styles,
            backgroundColor: (background && styles.backgroundColor) || '#fff'
        }
    }, [appId, background, pageBreakpointConfig?.design?.veins, palettes, parseBackgroundVariableImage])

    const layerLayoutStyle = useMemo(() => {
        // 如果以页面形式打开，我们已经做了页面背景的处理，不需额外给页面容器添加背景
        if (isPageOpenedAsPage && isRootPage) {
            return
        }

        return {
            ...backgroundStyle,
            backgroundAttachment: 'fixed'
        }
    }, [backgroundStyle, isPageOpenedAsPage, isRootPage])

    return (
        <PageContainer>
            {isPageOpenedAsPage &&
                createPortal(
                    <div
                        style={{
                            ...backgroundStyle,
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            inset: 0,
                            zIndex: -1,
                            pointerEvents: 'none'
                        }}
                    />,
                    document.body
                )}
            <PageContainerProvider endpoint={endPoint}>
                <ScrollerArea ref={ref} id={PAGE_SCROLL_PARENT_CONTENT}>
                    <LayoutEngine
                        disabled
                        data={flowNodes}
                        size={pageBreakpointConfig?.size}
                        layout={pageBreakpointConfig?.layout}
                        design={pageBreakpointConfig?.design}
                        style={layerLayoutStyle}
                        parseBackgroundVariableImage={parseBackgroundVariableImage}
                        nodeRender={BlockRender}
                    />
                    {/* {stackDisplayType === 'page' && <DomainFiling data={domain} isMobile={isMobile} />} */}
                </ScrollerArea>
                {/* 悬浮视图工具栏 */}
                <PageSuspendPagination />
            </PageContainerProvider>
        </PageContainer>
    )
})
