import type { OnOffBlockAbstract } from '@lighthouse/core'
import React, { Suspense } from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'

const OnOffBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.OnOffBlock })))

interface OnOffBlockProps {
    blockData: OnOffBlockAbstract
}

const OnOffBlockController: React.FC<OnOffBlockProps> = ({ blockData }) => {
    const { handleActionTrigger } = useActionTrigger(blockData.id)

    return (
        <Suspense fallback={<div />}>
            <OnOffBlock onActionTrigger={handleActionTrigger} blockData={blockData} />
        </Suspense>
    )
}

export default OnOffBlockController
