import type { BlockAbstract } from '@lighthouse/core'
import type { NodeRenderProps } from '@lighthouse/shared'
import { findBlockById } from '@lighthouse/shared'
import { current } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import React from 'react'
import { debounce } from 'throttle-debounce'

import { syncComponentsAtom } from '@/atoms/application/state'
import { updateBlockAtom } from '@/atoms/page/action'
import BlockRenderController from '@/components/BlockControllers'
import { useCurrentPageContext } from '@/context/PageContext'
import { useCurrentAppId } from '@/hooks/useApplication'
import * as srv from '@/services'

const debounceUpdateBlock = debounce(500, (payload: BlockAbstract[]) => {
    return srv.updateSyncComponents(payload)
})

export const BlockRender = ({ node, children }: NodeRenderProps) => {
    const { pageId } = useCurrentPageContext()
    const currentAppId = useCurrentAppId()

    const { block } = node

    const onUpdateBlock = useAtomCallback((get, set, block: BlockAbstract) => {
        if (block.isLeafSynchronous || block.isMasterSynchronous) {
            set(syncComponentsAtom, draft => {
                const draftBlock = findBlockById(block.id, draft)
                if (!draftBlock) {
                    return
                }
                Object.entries(block).forEach(([k, v]) => {
                    Reflect.set(draftBlock, k, v)
                })
                debounceUpdateBlock(current(draft))
            })
        } else {
            set(updateBlockAtom, {
                appId: currentAppId,
                block,
                pageId
            })
        }
    })

    if (!block) {
        return children as JSX.Element
    }

    return (
        <BlockRenderController blockData={block} node={node} onBlockChange={onUpdateBlock}>
            {children}
        </BlockRenderController>
    )
}
