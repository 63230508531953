import type { FlowNode } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleRefreshPage = async (node: FlowNode, excParams: AnyObject) => {
    excParams.actionEvents.refreshPage?.()
    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: true
    })
}
