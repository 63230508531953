import { FloatBlock } from '@lighthouse/block'
import type { FloatBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { useFloatBoxContext } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import React from 'react'
import styled from 'styled-components'

interface FloatBlockProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
    blockData: FloatBlockAbstract
    node: FlowLayoutNode
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`

const FloatBlockController: React.FC<FloatBlockProps> = ({ blockData, node, ...rest }) => {
    return (
        <SCxContainer>
            <FloatBlock node={node} blockData={blockData} {...rest} />
        </SCxContainer>
    )
}

export default FloatBlockController
