import { UserCenterBlock } from '@lighthouse/block'
import type { UserCenterBlockAbstract } from '@lighthouse/core'
import React from 'react'

import { useAccount } from '@/hooks/useAccount'
import { usePreview } from '@/hooks/useApplication'

interface UserCenterBlockProps {
    blockData: UserCenterBlockAbstract
}

const UserCenterBlockController: React.FC<UserCenterBlockProps> = ({ blockData }) => {
    const previewType = usePreview()
    const user = useAccount()

    return <UserCenterBlock blockData={blockData} previewType={previewType} user={user} />
}

export default UserCenterBlockController
