import type { CollectEvent } from '@lighthouse/core'
import { EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import type { TriggerEventExtraOption } from '@lighthouse/shared'
import { initBlockRuntimeState, useAtomAction, useAtomData } from '@lighthouse/shared'
import { isWechatBrowser } from '@lighthouse/tools'
import React, { useEffect, useRef } from 'react'
import { useAsync, useEffectOnce, useUpdateEffect } from 'react-use'

import { syncComponentsAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { PageProvider, PageStackProvider } from '@/context/PageContext'
import { usePreview } from '@/hooks/useApplication'
import { useEventTrigger } from '@/hooks/useEventTrigger'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import * as srv from '@/services'
import { shareWechatMessageData } from '@/utils/wechat'

import { PageContent } from './PageContentV2'

interface PageContainerProviderProps {
    pageId: string
    stackId: string
    refreshPageManually?: () => void
}

type PageContainerProps = PageContainerProviderProps

export const PageContainer = React.forwardRef<HTMLDivElement, PageContainerProps>(({ pageId, stackId, refreshPageManually }, ref) => {
    const { handleTriggerEvent } = useEventTrigger()
    // 触发微信分享事件采集
    useConfigureWechatShare({ pageId, stackId, onTriggerEvent: handleTriggerEvent })
    // 触发访问页面事件采集
    useVisitPage(pageId, handleTriggerEvent)

    const previewType = usePreview()
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    // 切换断点时更新runtime state
    useUpdateEffect(() => {
        setPageStack(draft => {
            const stack = draft.find(equalPageStack(pageId, stackId))
            if (!stack) {
                return
            }
            initBlockRuntimeState(stack, { blocks: pageBlocks, syncComponents })
        })
    }, [previewType])

    return (
        <PageProvider value={{ pageId, refreshPage: refreshPageManually }}>
            <PageStackProvider value={stackId}>
                <PageContent ref={ref} />
            </PageStackProvider>
        </PageProvider>
    )
})

export function useVisitPage(
    pageId: string,
    onTriggerEvent: (collectEvents: CollectEvent[] | undefined, type: EVENT_VARIABLE_TYPE, options?: TriggerEventExtraOption) => void
) {
    const visitRef = useRef(false)
    const currentPage = useAtomData(pageAtomFamily(pageId))

    useEffectOnce(() => {
        if (!currentPage?.collectEvents || visitRef.current) {
            return
        }
        visitRef.current = true
        const collectEvent = currentPage?.collectEvents
        onTriggerEvent(collectEvent, EVENT_VARIABLE_TYPE.PAGE_VISIT, { pageName: currentPage.name })
    })
}

/**
 * 配置微信分享hooks
 * 根据自定义规则获取数据
 * 或者根据默认规则获取数据
 */
export function useConfigureWechatShare(params: {
    pageId: string
    stackId: string
    onTriggerEvent: (collectEvents: CollectEvent[], type: EVENT_VARIABLE_TYPE, options?: TriggerEventExtraOption) => void
}) {
    const { pageId, stackId, onTriggerEvent } = params
    const isWechat = isWechatBrowser()

    const currentPage = useAtomData(pageAtomFamily(pageId))

    const lastStack = useAtomData(lastPageOfStackAtom)
    const { prev, curr } = usePageDataSourceForVariableSelector(params)

    const defaultShareRes = useAsync(async () => {
        if (!isWechat) {
            return
        }
        const { title, subtitle, icon } = await srv.getWechatShareData({
            pageId: currentPage?.id || '',
            parentRecordId: prev.recordId,
            currentRecordId: curr.recordId
        })
        return { title, description: subtitle, image: icon }
    }, [currentPage?.id, curr.recordId])

    const isLastPage = !!lastStack && lastStack.stackId === stackId

    // const handleShareSuccess = useCallback(() => {
    //     const collectEvent = currentPage?.collectEvents
    //     if (!collectEvent || collectEvent.length === 0) {
    //         return
    //     }
    //     onTriggerEvent(collectEvent, EVENT_VARIABLE_TYPE.WECHAT_SHARE, { pageName: currentPage?.name })
    // }, [currentPage?.collectEvents, currentPage?.name, onTriggerEvent])

    // 设置微信分享
    useEffect(() => {
        if (!isWechat) {
            return
        }
        if (isLastPage && currentPage) {
            if (defaultShareRes.error || defaultShareRes.loading || !defaultShareRes.value) {
                return
            }
            const { title, description, image } = defaultShareRes.value

            shareWechatMessageData({ title, desc: description, imgUrl: image })
        }
    }, [defaultShareRes.error, defaultShareRes.loading, defaultShareRes.value, isLastPage, isWechat, curr.recordId, currentPage])
}
